import { useState, useEffect } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomePageOne from "../page/home/HomePageOne";
import Preloader from "./Preloader";
import { Helmet } from "react-helmet";
import FloatingForm from "../common/floating-form/FloatingForm";
function MainLayout() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Metadata
  const pageDescription =
    "Top-notch software house specializing in custom applications, website, and blockchain development. Delivering exceptional services tailored for your company.";

  const pageTitle =
    "Software Development Company | Oligamy Software | Custom Software for Organizations";

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <Header />
          <div
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <HomePageOne />
            <FloatingForm />
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default MainLayout;
