import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import "./index.css";

// Sub Pages

import UIUX from "./components/page/ui-ux/UiUx";
import blockChain from "./components/page/blockchain/BlockChain";
import machineLearning from "./components/page/machine-learning/MachineLearning";
import webAndMobile from "./components/page/web-and-mobile/WebAndMobile";

import CaseStudies from "./components/page/CaseStudies/CaseStudies";

import Blog from "./components/page/BlogStandard/BlogStandard";

import PrivacyPolicy from "./components/page/PrivacyPolicy";

// Case Studies

import Oddsee from "./components/page/CaseStudies/Oddsee";
import Wgmi from "./components/page/CaseStudies/Wgmi";
import Mthrbrd from "./components/page/CaseStudies/mthrbrd";
import Metaverse from "./components/page/CaseStudies/Metaverse";
import Billon from "./components/page/CaseStudies/Billon";

import PageNotFound from "./components/page/error/PageNotFound";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import { FormDataProvider } from "./components/common/floating-form/FormDataContext";
import { FormFeedbackProvider } from "./components/common/floating-form/FormFeedbackContext";
import ProductPassport from "./components/page/CaseStudies/ProductPassport";
import BriefForm from "./components/page/brief-form/BriefForm";
import FilipPuzyr from "./components/page/CaseStudies/FilipPuzyr";

//Default Warning Error Hide
// console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          {/*main layout*/}
          <Route exact path="/" component={MainLayout} />
          {/* all inner page load layout component */}
          <Layout>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/offer/ui-ux`}
                component={UIUX}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/offer/block-chain`}
                component={blockChain}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/offer/machine-learning`}
                component={machineLearning}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/offer/web-and-mobile`}
                component={webAndMobile}
              />
              {/*<Route*/}
              {/*  exact*/}
              {/*  path={`${process.env.PUBLIC_URL}/portfolio1`}*/}
              {/*  component={portfolio1}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  exact*/}
              {/*  path={`${process.env.PUBLIC_URL}/portfolio2`}*/}
              {/*  component={portfolio2}*/}
              {/*/>*/}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/oddsee`}
                component={Oddsee}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/wgmi`}
                component={Wgmi}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/mthrbrd`}
                component={Mthrbrd}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design`}
                component={CaseStudies}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/blog`}
                component={Blog}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/blog-post/:id`}
                component={BlogDetails}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/metaverse`}
                component={Metaverse}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/document-reader`}
                component={Billon}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/expectations-brief`}
                component={BriefForm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/privacy-policy`}
                component={PrivacyPolicy}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/filip-puzyr`}
                component={FilipPuzyr}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/product-passport`}
                component={ProductPassport}
              />
              <Route component={PageNotFound} />
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <FormDataProvider>
      <FormFeedbackProvider>
        <Root />
      </FormFeedbackProvider>
    </FormDataProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
