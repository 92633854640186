import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import logError from "../../../helpers/logError";
import getPosts from "../../../api/action.getPosts";
import sortBlogPosts from "../../../helpers/sortBlogPosts";

import data from "../../../data/dataBlogPosts";

function NewPostWidget() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [posts, setPosts] = useState([]);

  // Posts fetching

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw "No posts";
          }
          setPosts(sortBlogPosts(rawPosts));
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div className="sidebar-widget">
        <h4>New Post</h4>
        {posts.slice(0, 3).map((post, id) => {
          const img =
            post?.attributes?.thumbnail?.data?.attributes?.formats?.thumbnail
              ?.url;
          return (
            <div key={id} className="recent-post">
              <div className="recent-thumb">
                <Link
                  to={`${process.env.PUBLIC_URL}/design/blog-post/${post.id}`}
                  onClick={scrollTop}
                >
                  <img
                    src={process.env.REACT_APP_STRAPI_URL + img}
                    alt="Thumbnail"
                  />
                </Link>
              </div>
              <div className="recent-post-cnt">
                <span>{post?.attributes?.date}</span>
                <h5>
                  <Link
                    to={`${process.env.PUBLIC_URL}/design/blog-post/${post.id}`}
                    onClick={scrollTop}
                  >
                    {post?.attributes?.title}
                  </Link>
                </h5>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default NewPostWidget;
