import React, { useEffect, useState } from "react";
import { submitFormData } from "../../api/action.submitFormData";
import { useFormFeedback } from "./floating-form/FormFeedbackContext";
import PositiveFeedback from "./form-feedback/PositiveFeedback";
import NegativeFeedback from "./form-feedback/NegativeFeedback";

export default function FooterForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const [wasSubmitClicked, setWasSubmitClicked] = useState(false);

  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const { feedbackComponent, setFormFeedback } = useFormFeedback();

  const [formSubmitedFromFooter, setFormSubmitedFromFooter] = useState(false);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      let offset = 0;
      if (window.innerWidth < 500) {
        offset = 100;
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }
    }
  };

  const handlePersonalDataClick = (e) => {
    setIsConsentChecked(e.target.checked);
  };

  const handleCheckboxChange = (e) => {
    setIsConsentChecked(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error for the current input field when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Checking for empty fields
    if (!formData.name.trim()) {
      newErrors.name = "Name cannot be empty.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email cannot be empty.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setWasSubmitClicked(true);

    const isFormValid = validateForm();

    if (isFormValid && isConsentChecked) {
      const response = await submitFormData(formData);

      if (response.success) {
        // Handle successful form submission
        setFormFeedback(<PositiveFeedback />);
        setFormSubmitedFromFooter(true);
      } else {
        // Handle form submission errors
        setFormFeedback(<NegativeFeedback />);
        setFormSubmitedFromFooter(true);
      }
    }
  };

  useEffect(() => {
    // Scroll to #contact only if the form was submitted in FooterForm
    if (feedbackComponent && formSubmitedFromFooter) {
      scrollToElement("contact");
    }
  }, [feedbackComponent, formSubmitedFromFooter]);

  return (
    <div className="footer-form">
      {feedbackComponent || (
        <>
          <div className="upper">
            <div className="input-short">
              <p>Name</p>
              <input
                onChange={handleInputChange}
                type="text"
                name="name"
                placeholder="Enter name"
              />
              {errors.name && <p className="form-error">{errors.name}</p>}
            </div>
            <div className="input-short">
              <p>E-mail</p>
              <input
                onChange={handleInputChange}
                type="text"
                name="email"
                placeholder="Enter e-mail"
              />
              {errors.email && <p className="form-error">{errors.email}</p>}
            </div>
          </div>
          <div className="lower">
            <div className="input-long">
              <p>Message</p>
              <textarea
                onChange={handleInputChange}
                type="text"
                name="message"
                placeholder="A place for your message"
              />
              {errors.message && <p className="form-error">{errors.message}</p>}
            </div>
          </div>
          <div className="data-processing">
            <label className="custom-checkbox">
              <input
                checked={isConsentChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
                name="consent"
              />
              <span className="checkmark"></span>
            </label>
            <p
              onClick={handlePersonalDataClick}
              className={`personal-data ${
                !isConsentChecked && wasSubmitClicked ? "red" : ""
              }`}
            >
              * I consent to the processing of personal data in order to reply
              to the message.
            </p>
          </div>
          <div className="cmn-btn form">
            <a onClick={handleSubmit}>Send a message</a>
          </div>
        </>
      )}
    </div>
  );
}
