import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/ui-ux/ui-header-bg.png";
import AnimateLine from "../../common/AnimateLine";
import useMediaQueries from "../../common/MediaQueries";
import OurServiceFragment from "../../common/OurServiceFragment";
import Preloader from "../../layout/Preloader";
import Line1 from "../../../assets/img/oligamy/ui-ux/UI/1.png";
import Line2 from "../../../assets/img/oligamy/ui-ux/UI/2.png";
import Line3 from "../../../assets/img/oligamy/ui-ux/UI/3.png";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import FloatingForm from "../../common/floating-form/FloatingForm";

function UiUx() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const isMobileMedia = useMediaQueries("(max-width: 997px)");
  const isPhoneMedia = useMediaQueries("max-width: 500px");
  const isTabletMedia = useMediaQueries(
    "(min-width: 825px) and (max-width: 1023px)"
  );
  const isLargeMedia = useMediaQueries("(min-width: 999px)");

  //Metadata

  const pageTitle =
    "Global UX/UI Design & Animated Content | Oligamy Software & Design";
  const pageDescription =
    "Delivering top-tier UX/UI Design and creative mockups. Offering Animated Content Creation and dedicated UX/UI Audits. ";

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className="creasoft-wrap"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <AnimateLine />
            <section className="page-section-offer mb-[170px] 997px:mb-[100px]">
              <div className="position-relative">
                <div className="container wrapper max-w-[1320px]">
                  {isLargeMedia ? (
                    <h1 className="title ui-ux position-absolute">
                      UI/UX, graphics design
                      <br />
                      and animated content creation
                    </h1>
                  ) : (
                    <h1 className="title ui-ux position-absolute">
                      UI/UX, graphics design
                      <br />
                      and animated content creation
                    </h1>
                  )}
                </div>
                <img className="w-100 offer-header ui-ux" srcSet={TopPic} />
              </div>
              <div className="container wrapper max-w-[1320px] wow animated fadeInUp">
                <div className={`row ${!isMobileMedia && "mt-5 pt-5"}`}>
                  <div
                    className={`${
                      isMobileMedia ? "col-sm-12" : "col-sm-6"
                    } block-chain-offer`}
                  >
                    <h2 className="pt-5 h2-sub-page">
                      We do our best to implement our projects with the greatest
                      care
                    </h2>
                    <p className="py-4 p-sub-page">
                      From stunning website designs to seamless user
                      experiences, our talented team ensures visual excellence.
                      Need 2D or 3D graphics? We've got you covered! Whether
                      it's a logo, website design, animated video, or an NFT
                      collection, we deliver top-notch creations. With multiple
                      samples provided, you're in complete control of your
                      project's direction.
                    </p>
                  </div>
                  {!isMobileMedia && <OurServiceFragment />}
                </div>

                <div className="ui-ux-lower-wrapper">
                  <div className="design-plug-wrapper">
                    <h2>
                      Transforming Visions into UX/UI Designs: Explore Oligamy
                      Design Expertise
                    </h2>
                    <HashLink to={`${process.env.PUBLIC_URL}/design`}>
                      <button>See our case studies</button>
                    </HashLink>
                  </div>
                  <div className="description">
                    <div className="img-line ui-ux wow animated fadeInUp">
                      <img
                        src={Line1}
                        alt="Oddsee Style"
                        // className="991px:hidden"
                      />
                      <img
                        src={Line2}
                        alt="Oddsee Style"
                        // className="991px:hidden"
                      />
                      <img
                        src={Line3}
                        alt="Oddsee Style"
                        // className="991px:hidden"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isMobileMedia && <OurServiceFragment />}
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default UiUx;
