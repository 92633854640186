import { useEffect, useState } from "react";
import ProjectCardFilterCaseStudies from "./ProjectCardFilterCaseStudies";

function ProjectWrap(props) {
  const [isMore, setIsMore] = useState(false);

  const seeMoreHandler = () => {
    setIsMore(!isMore);
  };

  return (
    <>
      <div className="project-area design 767px:mb-[200px]" id="portfolio">
        <div className="title-wrap design m-[auto] wow animate fadeInUp">
          <div className={`${"sec-title"} ${props.white} ${"sec-title_alt"}`}>
            <h3 className="case-studies-header">CASE STUDIES</h3>
            <h2>See our projects now</h2>
            <p className="text-[#565656]">
              We take pride in collaborating closely with you to comprehend your
              unique requirements fully. Our dedicated design teams then
              translate your vision into stunning, high-performing designs that
              captivate your audience.
            </p>
          </div>
        </div>
        <ProjectCardFilterCaseStudies
          isMore={isMore}
          windowSize={props.windowSize}
        />
        {props.windowSize <= 991 && props.windowSize >= 767 ? (
          <></>
        ) : (
          <div className="toggle-btn-wrapper" id="seeMore">
            <button
              className="design-case-studies-toggle"
              onClick={seeMoreHandler}
            >
              See {isMore ? "less" : "more"} case studies
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default ProjectWrap;
