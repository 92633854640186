import { useEffect, useRef, useState } from "react";
import Preloader from "../../layout/Preloader";
import ProgressBar from "./ProgressBar";
import AnswerDatabase from "./AnswerDatabase";
import Questionnaire from "./Questionnaire";
import SummaryScreen from "./SummaryScreen";
import AbandonForm from "./AbandonForm";

function BriefForm() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [progress, setProgress] = useState(0);
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [abandonFormToggle, setAbandonFormToggle] = useState(false);
  const [isScrollActive, setIsScrollActive] = useState(false);

  const [userResponses, setUserResponses] = useState({
    step1: { selectedOptions: [], textareaValue: null },
    step2: { section1Option: [], section2Option: [] },
    step3: { selectedOptions: [], textareaValue: null },
    step4: { section1Option: [], section2Option: [] },
    step5: { textareaValue: null, emailValue: null },
  });
  const handleAbandonFormToggle = () => {
    setAbandonFormToggle(!abandonFormToggle);
  };

  useEffect(() => {
    if (abandonFormToggle) {
      // Add 'no-scroll' class to body when abandonFormToggle is true
      document.body.classList.add("no-scroll");
    } else {
      // Remove 'no-scroll' class from body when abandonFormToggle is false
      document.body.classList.remove("no-scroll");
    }
  }, [abandonFormToggle]);

  useEffect(() => {
    const answerContainer = document.querySelector(".answers-container");

    if (answerContainer) {
      const handleScroll = () => {
        const isScrolling =
          answerContainer.clientHeight < answerContainer.scrollHeight;
        setIsScrollActive(isScrolling);
      };

      // Listen for scroll events on the answerContainer
      answerContainer.addEventListener("scroll", handleScroll);

      // Initial check for scroll position
      handleScroll();

      // Cleanup: remove the event listener when the component unmounts
      return () => {
        answerContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [currentStep]);

  useEffect(() => {
    if (progress > 0) {
      setIsProgressVisible(true);
    } else setIsProgressVisible(false);
  }, [progress]);

  const handlePreviousStep = () => {
    // Increment the current step and progress when the user clicks the "Next" button
    const newProgress = progress - 20;

    setCurrentStep((prevStep) => prevStep - 1);

    // Scroll to the top of the page
    window.scrollTo({ top: 80, behavior: "smooth" });

    // Check if the new progress value exceeds 100
    if (newProgress <= 100) {
      setProgress(newProgress);
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      // handleAbandonFormToggle();
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          {abandonFormToggle && (
            <AbandonForm handleAbandonFormToggle={handleAbandonFormToggle} />
          )}

          {currentStep === 6 ? (
            <SummaryScreen
              progress={progress}
              userResponses={userResponses}
              handleAbandonFormToggle={handleAbandonFormToggle}
              setUserResponses={setUserResponses}
              isProgressVisible={isProgressVisible}
              handlePreviousStep={handlePreviousStep}
            />
          ) : (
            <section className="page-section">
              <div className="container max-w-[1320px] brief">
                <div
                  className="go-back-btn mt-[136px] flex items-center gap-[16px]"
                  onClick={handleAbandonFormToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                  >
                    <path d="M5.99935 11.3337L0.666016 6.00033L5.99935 0.666992L6.94935 1.60033L3.21602 5.33366H11.3327V6.66699H3.21602L6.94935 10.4003L5.99935 11.3337Z" />
                  </svg>
                  <p className="text-[#6c6c6c]">Go to homepage</p>
                </div>
                <div className="upper mt-[24px]">
                  <h1 className="text-[61px] font-[500] text-[#0e2933] tracking-[-2.44px] 500px:text-[39px] 500px:font-400 500px:tracking-[-1.56px] leading-[110%]">
                    Customer expectations brief
                  </h1>
                  <h4 className="mt-[16px] text-[#173d46] text-[24px] tracking-[-0.25px] font-[400] 500px:text-[22px] 500px:leading-[120%]">
                    Get to know our offer with the help of this form
                  </h4>
                  <ProgressBar
                    progress={progress}
                    isProgressVisible={isProgressVisible}
                  />
                </div>
                <div className="lower mt-[72px] flex justify-between gap-[115px] 799px:flex-col">
                  <div className="left w-full flex-1 max-w-[721px]">
                    <h3 className="text-[#0e2933] text-left text-[34px] font-500 tracking-[-0.34px] mb-[32px] 500px:text-[29px] 500px:font-400 ">
                      {currentStep === 5
                        ? "The last step"
                        : "What can we do for You?"}
                    </h3>
                    <Questionnaire
                      progress={progress}
                      setProgress={setProgress}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      userResponses={userResponses}
                      setUserResponses={setUserResponses}
                      setIsScrollActive={setIsScrollActive}
                      isScrollActive={isScrollActive}
                    />
                  </div>
                  <div className="right flex-1">
                    <h4 className="text-[#173d46] text-[25px] font-500 tracking-[-0.25px] mb-[24px]">
                      Answer database
                    </h4>
                    <AnswerDatabase
                      userResponses={userResponses}
                      currentStep={currentStep}
                      isScrollActive={isScrollActive}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
}

export default BriefForm;
