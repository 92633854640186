import ServiceCardWrap from "./ServiceCardWrap";

function ServiceWrap() {
  return (
    <>
      <ServiceCardWrap />
    </>
  );
}

export default ServiceWrap;
