import { Link } from "react-router-dom";
function Breadcrumb(props) {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt ">
                  <h1>{props.pageTitle}</h1>
                  <span>
                    <Link
                      to={`${process.env.PUBLIC_URL}${props.earlierPageUrl}`}
                    >
                      {props.earlierPage}
                    </Link>
                    <i className="bi bi-arrow-right" />
                    {props.pageName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;
