import React, { useState, useEffect } from "react";

export default function ThirdStep({
  onNextStep,
  setUserResponses,
  onPreviousStep,
  userResponses,
}) {
  const [selectedOptions, setSelectedOptions] = useState(
    userResponses.step3.selectedOptions || []
  );
  const [textareaValue, setTextareaValue] = useState(
    userResponses.step3.textareaValue || ""
  );

  const [isOtherSelected, setIsOtherSelected] = useState(
    userResponses.step3.selectedOptions
      ? userResponses.step3.selectedOptions.includes(
          "Other special features (please specify)"
        )
      : false
  );

  const [clickedNext, setClickedNext] = useState(false);

  const optionsShoppingAndUser = [
    "Cart functions",
    "Online payment system",
    "Login and registration functions",
    "Ratings and reviews system",
    "Personalization",
    "Interactive forms",
  ];

  const optionsContentAndWebsite = [
    "Content management (CMS)",
    "Search engine",
    "Interactive graphic elements",
    "Several languages",
  ];

  const optionsSocialInteractionAndLocation = [
    "Social media integration",
    "Maps and location",
    "Contact customer service",
  ];

  const optionsOtherStepOne = ["Other special features (please specify)"];

  const handleOptionChange = (option) => {
    if (option === optionsOtherStepOne[0]) {
      return;
    } else {
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];

      setSelectedOptions(updatedOptions);

      // Check if "Other special features" is selected
      setIsOtherSelected(
        updatedOptions.includes("Other special features (please specify)")
      );
    }
  };

  const handleTextareaToggle = () => {
    setIsOtherSelected(!isOtherSelected);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleNextStep = () => {
    // Set the state to indicate that the user has clicked the "Next Step" button
    setClickedNext(true);

    // Perform validation: Check if the user has selected at least one option
    if (selectedOptions.length === 0 && !isOtherSelected) {
      // Display an error message or handle the lack of selection in an appropriate way
      return;
    }

    // You can perform additional validation or data processing here before moving to the next step
    setUserResponses((prevUserResponses) => ({
      ...prevUserResponses,
      step3: {
        ...prevUserResponses.step3,
        selectedOptions: selectedOptions,
        textareaValue: isOtherSelected ? textareaValue : "", // Set textareaValue only if "Other special features" is selected
      },
    }));
    onNextStep();
  };

  const handlePreviousStep = () => {
    onPreviousStep();
  };

  useEffect(() => {
    setSelectedOptions(userResponses.step3.selectedOptions || []);
    setTextareaValue(userResponses.step3.textareaValue || "");
    setIsOtherSelected(
      userResponses.step3.selectedOptions
        ? userResponses.step3.selectedOptions.includes(
            "Other special features (please specify)"
          )
        : false
    );

    // Check if "Other special features" is selected based on existing data
    if (userResponses.step3.textareaValue) {
      setIsOtherSelected(true);
    }
  }, [userResponses.step3]);

  return (
    <div className="third-step">
      <h4 className="text-[25px] tracking-[-0.25px] text-[#173d46] mb-[16px] 500px:text-[22px]">
        Do you have any special features or interactions you expect from the
        design?
      </h4>
      <div className="questionnaire-section">
        <div className="questionnaire-title">
          <h4>Shopping and user interaction</h4>
          <div className="line"></div>
        </div>
        <div className="options">
          {optionsShoppingAndUser.map((option, index) => (
            <div className="option" key={index}>
              <input
                type="checkbox"
                id={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionChange(option)}
                className="custom-checkbox"
              />
              <label htmlFor={option} style={{ cursor: "pointer" }}>
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="questionnaire-section">
        <div className="questionnaire-title">
          <h4>Content and website presentation</h4>
          <div className="line"></div>
        </div>
        <div className="options">
          {optionsContentAndWebsite.map((option, index) => (
            <div className="option" key={index}>
              <input
                type="checkbox"
                id={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionChange(option)}
                className="custom-checkbox"
              />
              <label htmlFor={option} style={{ cursor: "pointer" }}>
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="questionnaire-section">
        <div className="questionnaire-title">
          <h4>Social interaction and location</h4>
          <div className="line"></div>
        </div>
        <div className="options">
          {optionsSocialInteractionAndLocation.map((option, index) => (
            <div className="option" key={index}>
              <input
                type="checkbox"
                id={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionChange(option)}
                className="custom-checkbox"
              />
              <label htmlFor={option} style={{ cursor: "pointer" }}>
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="questionnaire-section">
        <div className="questionnaire-title">
          <h4>Other</h4>
          <div className="line"></div>
        </div>
        <div className="options">
          {optionsOtherStepOne.map((option, index) => (
            <div className="option" key={index}>
              <input
                type="checkbox"
                id={option}
                checked={
                  option === "Other special features (please specify)"
                    ? isOtherSelected
                    : selectedOptions.includes(option)
                }
                onChange={() => {
                  handleOptionChange(option);
                  if (option === "Other special features (please specify)") {
                    handleTextareaToggle();
                  }
                }}
                className="custom-checkbox"
              />
              <label htmlFor={option} style={{ cursor: "pointer" }}>
                {option}
              </label>
            </div>
          ))}
        </div>
        <textarea
          className="mt-[8px]"
          type="textarea"
          placeholder="Complete the field"
          value={textareaValue}
          onChange={handleTextareaChange}
          // Disable the textarea if "Other special features" is not selected
          disabled={!isOtherSelected}
        />
      </div>
      <div className="buttons-wrapper large">
        <button className="cancel-btn" onClick={handlePreviousStep}>
          Previous step
        </button>
        <div className={`error-message ${clickedNext ? "active" : ""}`}>
          <p>Please select at least one answer for each question</p>
        </div>
        <button className="next-step-btn" onClick={handleNextStep}>
          Next step
        </button>
      </div>
      <div className="buttons-wrapper small">
        <div className="buttons">
          <button className="cancel-btn" onClick={handlePreviousStep}>
            Previous step
          </button>
          <button className="next-step-btn" onClick={handleNextStep}>
            Next step
          </button>
        </div>
        <div className={`error-message ${clickedNext ? "active" : ""}`}>
          <p>Please select at least one answer for each question</p>
        </div>
      </div>
    </div>
  );
}
