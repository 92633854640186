import React, { useState, useEffect, useRef } from "react";
import FormContent from "./FormContent";

export default function FloatingForm() {
  const [isFloatingFormOpened, setIsFloatingFormOpened] = useState(false);
  const [isContactSectionInView, setIsContactSectionInView] = useState(false);
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);
  const [isFeedbackDisplayed, setIsFeedbackDisplayed] = useState(false);

  const floatingFormRef = useRef(null);

  const handleFloatingFormToggle = () => {
    setIsTransitionComplete(false);
    setIsFloatingFormOpened(!isFloatingFormOpened);
  };

  const handleClickOutside = (event) => {
    if (
      isFloatingFormOpened &&
      floatingFormRef.current &&
      window.innerWidth < 500 &&
      !floatingFormRef.current.contains(event.target)
    ) {
      setIsFloatingFormOpened(false);
    }
  };

  useEffect(() => {
    const timeoutDuration = 200;
    const timeoutId = setTimeout(() => {
      setIsTransitionComplete(true);
    }, timeoutDuration);
    return () => clearTimeout(timeoutId);
  }, [isFloatingFormOpened]);

  useEffect(() => {
    const handleScroll = () => {
      const contactSection = document.getElementById("contact");

      if (contactSection) {
        const contactSectionTop = contactSection.offsetTop;
        const contactSectionHeight = contactSection.clientHeight;

        // Check if the contact section is in the viewport or partially in view
        const isInView =
          window.scrollY >= contactSectionTop - window.innerHeight &&
          window.scrollY <= contactSectionTop + contactSectionHeight;

        setIsContactSectionInView(isInView);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    if (isFloatingFormOpened && window.innerWidth < 500) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    if (isFloatingFormOpened && window.innerWidth < 1000 && isLandscape) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isFloatingFormOpened]);

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    // Initial setup
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isFloatingFormOpened && window.innerWidth < 500 && (
        <div className="white-overlay" onClick={handleFloatingFormToggle} />
      )}
      {isFloatingFormOpened && window.innerWidth < 1000 && isLandscape && (
        <div className="white-overlay" onClick={handleFloatingFormToggle} />
      )}

      <div
        className={`floating-form`}
        style={{
          ...(isContactSectionInView
            ? { opacity: 0, pointerEvents: "none" }
            : {}),
          ...(window.innerWidth < 500 && isFloatingFormOpened
            ? {
                bottom: 0,
                right: 0,
                borderRadius: "16px 16px 0 0",
                zIndex: 1000,
              }
            : {}),
          ...(window.innerWidth < 1000 && isFloatingFormOpened && isLandscape
            ? {
                bottom: 0,
                right: 0,
                borderRadius: "16px 16px 0 0",
                zIndex: 1000,
              }
            : {}),
        }}
        ref={floatingFormRef}
      >
        {isFloatingFormOpened ? (
          <div
            className="opened"
            style={{ height: isFeedbackDisplayed ? 250 : 623 }}
          >
            {isTransitionComplete ? (
              <>
                {isLandscape && window.innerWidth < 1000 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div className="closeBtn-wrapper">
                        <div
                          className="closeBtn"
                          onClick={handleFloatingFormToggle}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g opacity="0.7">
                              <path
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#EFEFEF"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h2 style={{ width: "2500px" }}>
                        Creating better experiences together. Contact us.
                      </h2>
                    </div>
                    <FormContent
                      setIsFeedbackDisplayed={setIsFeedbackDisplayed}
                      isLandscape={isLandscape}
                    />
                  </>
                ) : (
                  <>
                    <div className="closeBtn-wrapper">
                      <div
                        className="closeBtn"
                        onClick={handleFloatingFormToggle}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g opacity="0.7">
                            <path
                              d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                              fill="#EFEFEF"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h2>Creating better experiences together. Contact us.</h2>
                    <FormContent
                      setIsFeedbackDisplayed={setIsFeedbackDisplayed}
                      isLandscape={isLandscape}
                    />
                  </>
                )}
              </>
            ) : null}
          </div>
        ) : (
          <div className="closed" onClick={handleFloatingFormToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <path
                d="M1 1H22V17H14L6 21V17H1V1ZM6 9H7V10H6V9ZM11 9H12V10H11V9ZM16 9H17V10H16V9Z"
                stroke="#FDFDFD"
                strokeWidth="2"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
