import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/wgmi/top-wgmi.png";
import AnimateLine from "../../common/AnimateLine";

import AboutImage from "../../../assets/img/oligamy/case-studies/wgmi/about.png";
import AboutImageTablet from "../../../assets/img/oligamy/case-studies/wgmi/about-tablet.png";
import ProcessImage from "../../../assets/img/oligamy/case-studies/wgmi/process.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/wgmi/about2.png";
import AboutImage2Tablet from "../../../assets/img/oligamy/case-studies/wgmi/about2-tablet.png";

import GuideUser from "../../../assets/img/oligamy/case-studies/wgmi/guideUser.png";
import GuideUser2 from "../../../assets/img/oligamy/case-studies/wgmi/guideUser2.png";

import StyleGuide from "../../../assets/img/oligamy/case-studies/wgmi/WGMIstyles.png";

import WgmiShapes from "../../../assets/img/oligamy/case-studies/wgmi/wgmi-shapes.png";

import Gallery11 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/1-1.png";
import Gallery12 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/1-2.png";

import Gallery21 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/2-1.png";
import Gallery22 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/2-2.png";
import Gallery23 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/2-3.png";

import Gallery31 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/3-1.png";
import Gallery32 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/3-2.png";
import Gallery33 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/3-3.png";
import Gallery34 from "../../../assets/img/oligamy/case-studies/wgmi/gallery/3-4.png";
import Preloader from "../../layout/Preloader";
import { Gallery, Item } from "react-photoswipe-gallery";
import FloatingForm from "../../common/floating-form/FloatingForm";

function Wgmi() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <AnimateLine />

          <section className="page-section">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Focused on delivering easy
                  <br className="519px:hidden" />
                  and comfortable user flow <br />
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} alt="Header" />
            </div>
            <div className="container max-w-[1320px]">
              <div className="app-data wow animated fadeIn">
                <h3>Client: WGMI FWC</h3>
                <h3>Year: 2022</h3>
              </div>
              <div className="about-app wgmi">
                <div className="text  wow animated fadeInUp">
                  <h1>Fast paced app development</h1>
                  <p>
                    Coming up with a good idea is only a part of the success,
                    another is to present it in such a way, which can be easily
                    understood.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="WGMI app"
                  className="wow animated fadeInUp wgmi-about1 991px:hidden"
                />
                <img
                  src={AboutImageTablet}
                  alt="WGMI app"
                  className="wow animated fadeInUp hidden 991px:block"
                />
              </div>
              <div className="process text animated fadeInUp">
                <h1>Process</h1>
                <div className="500px:hidden">
                  <img src={ProcessImage} alt="oddsee development process" />
                </div>
                <div className="hidden 500px:block">
                  <Gallery>
                    <Item
                      original={ProcessImage}
                      thumbnail={ProcessImage}
                      width="1190"
                      height="580"
                    >
                      {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={ProcessImage} />
                      )}
                    </Item>
                  </Gallery>
                </div>
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1>Landing Page</h1>
                <p className="text-[#565656] mb-[72px]">
                  Invitation of the user made in pleasing to the eye manner
                </p>
                <img
                  src={GuideUser}
                  alt="WGMI landing page"
                  className="wow animate fadeInUp wgmi-landing-page-img"
                />
              </div>
            </div>
            <div className="guide-user wgmi text wow animate fadeInUp">
              <h1>User flow above all</h1>
              <p className="text-[#565656] mb-[72px] max-w-[555px] text-center 911px:max-w-[90%] 569px:mb-[42px] 519px:max-w-[94%]">
                When the time is of the essence, fast prototyping on wireframes
                shines as a tool for achieving friction-free user flow
              </p>
              <img
                src={GuideUser2}
                alt="WGMI user flow"
                className="wow animate fadeInUp wgmi-userFlow-img"
              />
            </div>
            <div className="container max-w-[1320px]">
              <div className="about-app_alt wgmi-list_container">
                <div className="text wgmi-list">
                  <h1 className="wow animate fadeInUp toLeft">
                    Where the idea meets the user
                  </h1>
                  <ul>
                    <li>
                      <p>
                        Keeping the essential info visible, minding to not
                        overwhelm the user with redundant text
                      </p>
                    </li>
                    <li>
                      <p>
                        Checklist / roadmap, help the user see the whole process
                      </p>
                    </li>
                    <li>
                      <p>
                        Simple and widely known layout keeps the user focused on
                        making crucial decisions
                      </p>
                    </li>
                    <li>
                      <p>
                        High fidelity wireframes make it easy to test user flow,
                        and optimize user experience
                      </p>
                    </li>
                    <li>
                      <p>
                        Making decisions in smaller steps is easier, and keeps
                        the user engaged
                      </p>
                    </li>
                  </ul>
                </div>
                <img
                  src={AboutImage2}
                  alt="WGMI idea meets the user"
                  className=" wow animate fadeInUp wgmi-about2 991px:hidden"
                />
                <img
                  src={AboutImage2Tablet}
                  alt="WGMI idea meets the user"
                  className=" wow animate fadeInUp hidden 991px:block"
                />
              </div>
            </div>
            <div className="guide-user wgmi">
              <div className="text alt wow animate fadeInUp text-center mb-[73px] 569px:mb-[42px]">
                <h1 className="500px:text-center">Style guide</h1>
                <p className="text-[#565656] max-w-[555px] 911px:max-w-[90%] 519px:max-w-[94%]">
                  After working on optimizing wireframes, we can designate key
                  repeating components to reduce development and styling time
                </p>
              </div>
              <img
                className="mb-[144px] 1399px:max-w-[100%] 799px:mb-[72px] 599px:max-w-[94%] wgmi-styleGuide-img"
                src={StyleGuide}
                alt="WGMI style guide"
              />
            </div>
            <div className="container max-w-[1320px]">
              <div className="wgmi-shapes">
                <img src={WgmiShapes} alt="wgmi shapes" />
              </div>
            </div>
            <div className="wgmi-gallery-wrapper">
              <div
                className="wgmi-gallery"
                style={{ display: "flex", gap: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={Gallery11} alt="" />
                  <img src={Gallery12} alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={Gallery21} alt="" />
                  <img src={Gallery22} alt="" />
                  <img src={Gallery23} alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={Gallery31} alt="" />
                  <img src={Gallery32} alt="" />
                  <img src={Gallery33} alt="" />
                  <img src={Gallery34} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Wgmi;
