import { useState, useEffect } from 'react';

//Refactor deprecated media.addListener and media.removeListener
export default function useMediaQueries(matchMedia) {
    const [matches, setMatches] = useState(window.matchMedia(matchMedia).matches);

    useEffect(() => {
        const media = window.matchMedia(matchMedia);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matchMedia, matches]);

    return matches;
}