import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/billon/top.png";
import AnimateLine from "../../common/AnimateLine";

import AboutImage from "../../../assets/img/oligamy/case-studies/billon/about.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/billon/about2.png";

import JourneyMap from "../../../assets/img/oligamy/case-studies/billon/our-task-main.png";

import Line1 from "../../../assets/img/oligamy/case-studies/billon/line/1.png";
import Line2 from "../../../assets/img/oligamy/case-studies/billon/line/2.png";
import Line3 from "../../../assets/img/oligamy/case-studies/billon/line/3.png";

import Preloader from "../../layout/Preloader";

import Preview1 from "../../../assets/img/oligamy/case-studies/billon/preview/1.png";
import Preview2 from "../../../assets/img/oligamy/case-studies/billon/preview/2.png";
import Preview3 from "../../../assets/img/oligamy/case-studies/billon/preview/3.png";
import Preview4 from "../../../assets/img/oligamy/case-studies/billon/preview/4.png";

import Ipad1 from "../../../assets/img/oligamy/case-studies/billon/ipad/1.png";
import Ipad2 from "../../../assets/img/oligamy/case-studies/billon/ipad/2.png";
import Ipad3 from "../../../assets/img/oligamy/case-studies/billon/ipad/3.png";
import Ipad4 from "../../../assets/img/oligamy/case-studies/billon/ipad/4.png";

import ReportsImg from "../../../assets/img/oligamy/case-studies/billon/reports.png";

import ThreeViews from "../../../assets/img/oligamy/case-studies/billon/three-views.png";

import DesignSystem from "../../../assets/img/oligamy/case-studies/billon/design-system.png";

import Screen1_1 from "../../../assets/img/oligamy/case-studies/billon/screens/col1/1.png";
import Screen1_2 from "../../../assets/img/oligamy/case-studies/billon/screens/col1/2.png";

import Screen2_1 from "../../../assets/img/oligamy/case-studies/billon/screens/col2/1.png";
import Screen2_2 from "../../../assets/img/oligamy/case-studies/billon/screens/col2/2.png";
import Screen2_3 from "../../../assets/img/oligamy/case-studies/billon/screens/col2/3.png";

import Screen3_1 from "../../../assets/img/oligamy/case-studies/billon/screens/col3/1.png";
import Screen3_2 from "../../../assets/img/oligamy/case-studies/billon/screens/col3/2.png";
import Screen3_3 from "../../../assets/img/oligamy/case-studies/billon/screens/col3/3.png";

import BillonSwiper from "./BillonSwiper";
import ReactPlayer from "react-player/lazy";

import Film1 from "../../../assets/img/oligamy/case-studies/billon/films/1.mp4";
import Film2 from "../../../assets/img/oligamy/case-studies/billon/films/2.mp4";
import Film3 from "../../../assets/img/oligamy/case-studies/billon/films/3.mp4";
import FloatingForm from "../../common/floating-form/FloatingForm";

function Billon() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <AnimateLine />

          <section className="page-section">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Billon's innovative blockchain <br />
                  solutions in finance <br />
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div className="app-data wow animated fadeIn">
                <h3>Client: Billon</h3>
                <h3>Year: 2022/2023</h3>
              </div>
              <div className="about-app wgmi billon">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">
                    Transforming data management: Billon's Innovative blockchain
                    solutions in finance
                  </h1>
                  <p className="billon">
                    Billon is a leading provider of innovative blockchain-based
                    solutions. We specialize in the financial sector, offering
                    secure and reliable tools for data storage and processing.
                    With our extensive knowledge and experience in the
                    blockchain field, we aim to revolutionize the way financial
                    institutions and other industries manage their information.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="billon preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="billon-preview">
                <div className="left">
                  <img src={Preview1} alt="" />
                  <img src={Preview3} alt="" />
                </div>
                <div className="right">
                  <img src={Preview2} alt="" />
                  <img src={Preview4} alt="" />
                </div>
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1>Our task</h1>
                <p className="text-[#565656] mb-[72px] max-w-[670px] text-center 911px:max-w-[100%]">
                  Our main task was to develop a comprehensive application that
                  would allow users to read, verify, sign, and publish various
                  types of documents, all using advanced blockchain technology.
                </p>
                <img
                  src={JourneyMap}
                  alt="billon private documents view"
                  className="wow animate fadeInUp"
                />
                <div className="img-line billon wow animate fadeInUp">
                  <img src={Line1} alt="Landing preview" />
                  <img src={Line2} alt="Landing preview" />
                  <img src={Line3} alt="Landing preview" />
                </div>
              </div>
              <div className="film">
                <ReactPlayer
                  url={Film1}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="billon-ipad mb-[144px] 799px:hidden">
                <div className="upper">
                  <img src={Ipad1} alt="" />
                  <img src={Ipad2} alt="" />
                </div>
                <div className="lower">
                  <img src={Ipad3} alt="" />
                  <img src={Ipad4} alt="" />
                </div>
              </div>
              <div className="hidden 799px:block 500px:mt-[48px] 799px:mb-[48px]">
                <BillonSwiper />
              </div>
            </div>
            <div className="project-challenge">
              <h1>Project challenge</h1>
              <div className="container max-w-[1320px] wrapper">
                <div className="upper">
                  <div className="tile highlighted">
                    <div className="top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="min-w-[20px] min-h-[20px]"
                      >
                        <path
                          d="M9.73206 5.76105C7.54006 5.76105 5.76105 7.54006 5.76105 9.73206C5.76105 11.9241 7.54006 13.7031 9.73206 13.7031C11.9241 13.7031 13.7031 11.9241 13.7031 9.73206C13.7031 7.54006 11.9241 5.76105 9.73206 5.76105ZM9.73206 1.79004C5.34806 1.79004 1.79004 5.34806 1.79004 9.73206C1.79004 14.1161 5.34806 17.6741 9.73206 17.6741C14.1161 17.6741 17.6741 14.1161 17.6741 9.73206C17.6741 5.34806 14.1161 1.79004 9.73206 1.79004ZM9.73206 16.0857C6.22169 16.0857 3.37844 13.2424 3.37844 9.73206C3.37844 6.22169 6.22169 3.37844 9.73206 3.37844C13.2424 3.37844 16.0857 6.22169 16.0857 9.73206C16.0857 13.2424 13.2424 16.0857 9.73206 16.0857Z"
                          fill="#0059FF"
                        />
                      </svg>
                      <h3>Secure document management platform</h3>
                    </div>
                    <div className="bottom">
                      <p>
                        Creating an advanced platform that allows for the secure
                        and immutable storage, verification, and signing of
                        important documents. Billon aimed to provide a solution
                        that grants users full control over their data and
                        ensures the highest security standards.
                      </p>
                    </div>
                  </div>
                  <div className="tile">
                    <div className="top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10.3229 1.79004C5.93888 1.79004 2.38086 5.34806 2.38086 9.73206C2.38086 14.1161 5.93888 17.6741 10.3229 17.6741C14.7069 17.6741 18.2649 14.1161 18.2649 9.73206C18.2649 5.34806 14.7069 1.79004 10.3229 1.79004ZM10.3229 16.0857C6.81251 16.0857 3.96926 13.2424 3.96926 9.73206C3.96926 6.22169 6.81251 3.37844 10.3229 3.37844C13.8333 3.37844 16.6765 6.22169 16.6765 9.73206C16.6765 13.2424 13.8333 16.0857 10.3229 16.0857Z"
                          fill="#E1EBFE"
                        />
                      </svg>
                      <h3>Modular and customizable application</h3>
                    </div>
                    <div className="bottom">
                      <p>
                        Ensuring scalability and flexibility so that the
                        application can handle various types of documents and
                        adapt to changing user needs. The application should be
                        modular and easily expandable, allowing users to choose
                        features according to their individual requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lower">
                  <div className="tile">
                    <div className="top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10.3229 1.79004C5.93888 1.79004 2.38086 5.34806 2.38086 9.73206C2.38086 14.1161 5.93888 17.6741 10.3229 17.6741C14.7069 17.6741 18.2649 14.1161 18.2649 9.73206C18.2649 5.34806 14.7069 1.79004 10.3229 1.79004ZM10.3229 16.0857C6.81251 16.0857 3.96926 13.2424 3.96926 9.73206C3.96926 6.22169 6.81251 3.37844 10.3229 3.37844C13.8333 3.37844 16.6765 6.22169 16.6765 9.73206C16.6765 13.2424 13.8333 16.0857 10.3229 16.0857Z"
                          fill="#E1EBFE"
                        />
                      </svg>
                      <h3>Straightforward and intuitive app handling</h3>
                    </div>
                    <div className="bottom">
                      <p>
                        Ensuring an easy and user-friendly user interface that
                        doesn't require specific technical skills. The
                        application should be accessible to a wide range of
                        users, regardless of their level of technological
                        proficiency.
                      </p>
                    </div>
                  </div>
                  <div className="tile">
                    <div className="top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10.3229 1.79004C5.93888 1.79004 2.38086 5.34806 2.38086 9.73206C2.38086 14.1161 5.93888 17.6741 10.3229 17.6741C14.7069 17.6741 18.2649 14.1161 18.2649 9.73206C18.2649 5.34806 14.7069 1.79004 10.3229 1.79004ZM10.3229 16.0857C6.81251 16.0857 3.96926 13.2424 3.96926 9.73206C3.96926 6.22169 6.81251 3.37844 10.3229 3.37844C13.8333 3.37844 16.6765 6.22169 16.6765 9.73206C16.6765 13.2424 13.8333 16.0857 10.3229 16.0857Z"
                          fill="#E1EBFE"
                        />
                      </svg>
                      <h3>Security and compliance with regulations</h3>
                    </div>
                    <div className="bottom">
                      <p>
                        The application is to be compliant with the latest data
                        protection standards and regulations. Security and
                        compliance with regulations were crucial for their
                        clients, making meeting these requirements a top
                        priority.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="guide-user wgmi text wow animate fadeInUp">
                <img
                  src={ReportsImg}
                  alt="reports preview"
                  className="wow animate fadeInUp"
                />
              </div>
              <div className="about-app wgmi mt-[96px] billon2 500px:mt-[88px]">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">User personas</h1>
                  <p>
                    Our user personas represent various fields and needs,
                    including legal, human resources, sales, and freelance work.
                    They all expect tools that enable secure and efficient
                    document management, including digital signing. Their common
                    goal is to streamline documentation processes and enhance
                    control over them.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="document verification view"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp mt-0">
                <img
                  src={ThreeViews}
                  alt="mobile app preview"
                  className="wow animate fadeInUp"
                />
              </div>
              <div className="film">
                <ReactPlayer
                  url={Film2}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1>Design system</h1>
                <p className="text-[#565656] mb-[72px] max-w-[670px] text-center 911px:max-w-[100%]">
                  The use of the ready-made MUI Design System by Billon brings
                  several benefits, such as time savings, cost reduction,
                  maintaining brand consistency, and the ability to tailor it to
                  their own needs. With MUI, the company can focus on its core
                  product and take advantage of regular updates and support from
                  the developer community.
                </p>
                <img
                  src={DesignSystem}
                  alt="design system"
                  className="wow animate fadeInUp"
                />
              </div>
              <div className="film">
                <ReactPlayer
                  url={Film3}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1>Screens</h1>
                <div className="screens-wrapper billon mt-[96px] 991px:mt-[40px]">
                  <div className="screens-single-column mt-[358px] 991px:mt-[108px]">
                    <img src={Screen1_1} alt="" />
                    <img src={Screen1_2} alt="" />
                  </div>
                  <div className="screens-single-column mt-[180px] mb-[140px] 991px:mt-[54px] 991px:mb-[96px]">
                    <img src={Screen2_1} alt="" />
                    <img src={Screen2_2} alt="" />
                    <img src={Screen2_3} alt="" />
                  </div>
                  <div className="screens-single-column">
                    <img src={Screen3_1} alt="" />
                    <img src={Screen3_2} alt="" />
                    <img src={Screen3_3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Billon;
