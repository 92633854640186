import { useEffect, useState } from "react";
import BlogStandarLayoutTwo from "./BlogStandarLayoutTwo";
import SearchWidget from "../blog/SearchWidget";
import CatagoryWidget from "../blog/CatagoryWidget";
import NewPostWidget from "../blog/NewPostWidget";
import PostTagWidget from "../blog/PostTagWidget";

import data from "../../../data/dataBlogPosts";
import getPosts from "../../../api/action.getPosts";
import sortBlogPosts from "../../../helpers/sortBlogPosts";
import logError from "../../../helpers/logError";
import { Helmet } from "react-helmet";

function BlogStandardWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [posts, setPosts] = useState([]);

  // Posts fetching

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw "No posts";
          }
          setPosts(sortBlogPosts(rawPosts));
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const [currentPosts, setCurrentPosts] = useState(posts);

  // Set currentPosts when posts change
  useEffect(() => {
    setCurrentPosts(posts);
  }, [posts]);

  // Search Bar Functionality

  const [query, setQuery] = useState("");
  useEffect(() => {
    if (query === "") {
      setCurrentPosts(posts);
    } else {
      setCurrentPosts(
        posts.filter((post) =>
          post?.attributes?.title.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }, [query]);

  //Metadata

  const pageTitle = "Software & Design Insights | Oligamy Blog Articles";
  const pageDescription =
    "Stay updated with the latest design trends and software technologies. Dive into insights on React, Angular, and more in our resourceful blog.";

  return (
    <>
      <Helmet>
        <meta name="description" content={pageDescription} />
        {/* Open Graph Protocol (OGP) tags for Facebook and others */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />{" "}
        {/* Use the current page URL */}
        <meta property="og:type" content="website" />
        {/* Twitter Card tags for Twitter */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div
        className="creasoft-wrap"
        onClick={() => {
          document.querySelector(".main-nav").className.includes("slidenav") &&
            document.querySelector(".remove").click();
        }}
      >
        <div className="blog-standard sec-mar-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 or-2">
                <div className="blog-standard-details">
                  {currentPosts.map((post, id) => {
                    const img =
                      post?.attributes?.thumbnail?.data?.attributes?.formats
                        ?.large?.url;
                    return (
                      <div
                        key={id}
                        onClick={scrollTop}
                        className="blog-main-post-wrapper"
                      >
                        <BlogStandarLayoutTwo
                          key={post.id}
                          image={img}
                          date={post.attributes.date}
                          title={post.attributes.title}
                          author={post.attributes.author}
                          postID={post.id}
                          handlePostSelection={() => {}}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="pagination-wrapper blog">
                  {currentPosts.length === 0 && <h1>No posts found</h1>}
                </div>
              </div>
              <div className="col-lg-4 or-1">
                <SearchWidget setQuery={setQuery} />
                {/*<CatagoryWidget />*/}
                <div className="500px:hidden">
                  <NewPostWidget />
                  {/*<PostTagWidget />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogStandardWrap;
