import ServiceWrap from "./ServiceWrap";

function Service() {
  return (
    <>
      <div className="creasoft-wrap">
        <ServiceWrap />
      </div>
    </>
  );
}

export default Service;
