import Breadcrumb from "../../common/Breadcrumb";
import BlogDetailsWrap from "./BlogDetailsWrap";
import { useEffect, useState } from "react";
import Preloader from "../../layout/Preloader";

function BlogDetails() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <Breadcrumb
            pageName="blog details"
            pageTitle="Oligamy Design Blog"
            earlierPage="Blog"
            earlierPageUrl="/design/blog"
          />
          <BlogDetailsWrap />
        </div>
      )}
    </>
  );
}

export default BlogDetails;
