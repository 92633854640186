import { NavLink } from "react-router-dom";
function FeaturesArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const classNameGrid =
    "col-md-6 col-xxl-3 wow animate fadeInUp flex 767px:justify-center 519px:pl-[16px] 519px:pr-[16px]";
  return (
    <>
      <section className="features-area" id="offer">
        <div className="container 1399px:max-w-[100%]">
          <div
            className="title-wrap_alt  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title sec-title_alt offer-title">
              <h3>OUR OFFER</h3>
              <h2>What we can do for you</h2>
            </div>
          </div>
          <div className="row g-4">
            <div
              className={`${classNameGrid} justify-end 767px:justify-center`}
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="upper-section flex justify-between items-center">
                    <div className="icon_wrapper">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/coneIcon.svg"
                          }
                          alt="Feature-img"
                        />
                      </div>
                      <div className="eclipse">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/eclipseIcon.svg"
                          }
                          alt="eclipse icon"
                        />
                      </div>
                    </div>
                    <div className="numbers">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/numbers/01.svg"
                        }
                        alt="01"
                      />
                    </div>
                  </div>
                  <h4>Blockchain development</h4>
                  <div className="read-more_wrapper">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/offer/block-chain`}
                      onClick={scrollTop}
                    >
                      <h2>Read more</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/triangleIcon.svg"
                        }
                        alt="Triangle icon"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNameGrid}
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="upper-section flex justify-between items-center">
                    <div className="icon_wrapper">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/robotIcon.svg"
                          }
                          alt="Feature-img"
                        />
                      </div>
                      <div className="eclipse">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/eclipseIcon.svg"
                          }
                          alt="eclipse icon"
                        />
                      </div>
                    </div>
                    <div className="numbers">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/numbers/02.svg"
                        }
                        alt="02"
                      />
                    </div>
                  </div>
                  <h4>Machine learning and artificial intelligence</h4>
                  <div className="read-more_wrapper">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/offer/machine-learning`}
                      onClick={scrollTop}
                    >
                      <h2>Read more</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/triangleIcon.svg"
                        }
                        alt="Triangle icon"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${classNameGrid} justify-end 767px:justify-center`}
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="upper-section flex justify-between items-center">
                    <div className="icon_wrapper">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/closingTagIcon.svg"
                          }
                          alt="Feature-img"
                        />
                      </div>
                      <div className="eclipse">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/eclipseIcon.svg"
                          }
                          alt="eclipse icon"
                        />
                      </div>
                    </div>
                    <div className="numbers">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/numbers/03.svg"
                        }
                        alt="03"
                      />
                    </div>
                  </div>
                  <h4>Web and mobile applications development</h4>
                  <div className="read-more_wrapper">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/offer/web-and-mobile`}
                      onClick={scrollTop}
                    >
                      <h2>Read more</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/triangleIcon.svg"
                        }
                        alt="Triangle icon"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNameGrid}
              data-wow-delay="800ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="upper-section flex justify-between items-center">
                    <div className="icon_wrapper">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/squareIcon.svg"
                          }
                          alt="Feature-img"
                        />
                      </div>
                      <div className="eclipse">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/offer-icons/eclipseIcon.svg"
                          }
                          alt="eclipse icon"
                        />
                      </div>
                    </div>
                    <div className="numbers">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/numbers/04.svg"
                        }
                        alt="04"
                      />
                    </div>
                  </div>
                  <h4>UI/UX, graphics design and animated content creation</h4>
                  <div className="read-more_wrapper">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/offer/ui-ux`}
                      onClick={scrollTop}
                    >
                      <h2>Read more</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/offer-icons/triangleIcon.svg"
                        }
                        alt="Triangle icon"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturesArea;
