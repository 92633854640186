import React, { useState, useEffect } from "react";
import { submitFormData } from "../../../api/action.submitFormData";
import PositiveFeedback from "../../common/form-feedback/PositiveFeedback";
import NegativeFeedback from "../../common/form-feedback/NegativeFeedback";
import { useFormData } from "./FormDataContext";
import { useFormFeedback } from "./FormFeedbackContext";

export default function FormContent({ setIsFeedbackDisplayed, isLandscape }) {
  const { formData, setFormData } = useFormData();
  const [errors, setErrors] = useState({});
  const [wasSubmitClicked, setWasSubmitClicked] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const { feedbackComponent, formSource, setFormFeedback } = useFormFeedback();

  const handlePersonalDataClick = (e) => {
    setIsConsentChecked(e.target.checked);
  };

  const handleCheckboxChange = (e) => {
    setIsConsentChecked(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error for the current input field when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Checking for empty fields
    if (!formData.name.trim()) {
      newErrors.name = "Name cannot be empty.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email cannot be empty.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setWasSubmitClicked(true);

    const isFormValid = validateForm();

    if (isFormValid && isConsentChecked) {
      const response = await submitFormData(formData);

      if (response.success) {
        // Handle successful form submission
        setFormFeedback(<PositiveFeedback />, "formContent");
        setIsFeedbackDisplayed(true); // Notify FloatingForm that feedback is displayed
      } else {
        // Handle form submission errors
        setFormFeedback(<NegativeFeedback />, "formContent");
        setIsFeedbackDisplayed(true); // Notify FloatingForm that feedback is displayed
      }

      // Clear the form data after submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <div className="footer-form floating">
      {feedbackComponent || (
        <>
          {isLandscape && window.innerWidth < 1000 ? (
            <div
              className="landscape-wrapper"
              style={{ display: "flex", gap: 16 }}
            >
              <div className="upper">
                <div className="input-short">
                  <p>Name</p>
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.name ? " visible" : ""
                    }`}
                  >
                    {errors.name}
                  </p>
                </div>
                <div className="input-short">
                  <p>E-mail</p>
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="email"
                    placeholder="Enter e-mail"
                    value={formData.email}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.email ? " visible" : ""
                    }`}
                  >
                    {errors.email}
                  </p>
                </div>
                <div className="data-processing">
                  <label className="custom-checkbox">
                    <input
                      checked={isConsentChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      name="consent"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p
                    onClick={handlePersonalDataClick}
                    className={`personal-data ${
                      !isConsentChecked && wasSubmitClicked ? "red" : ""
                    }`}
                  >
                    * I consent to the processing of personal data in order to
                    reply to the message.
                  </p>
                </div>
              </div>
              <div className={"lower"}>
                <div className="input-long">
                  <p>Message</p>
                  <textarea
                    onChange={handleInputChange}
                    type="text"
                    name="message"
                    placeholder="A place for your message"
                    value={formData.message}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.message ? " visible" : ""
                    }`}
                  >
                    {errors.message}
                  </p>
                </div>
                <div className="floating-form-btn">
                  <a onClick={handleSubmit}>Send a mess</a>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="upper">
                <div className="input-short">
                  <p>Name</p>
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.name ? " visible" : ""
                    }`}
                  >
                    {errors.name}
                  </p>
                </div>
                <div className="input-short">
                  <p>E-mail</p>
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="email"
                    placeholder="Enter e-mail"
                    value={formData.email}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.email ? " visible" : ""
                    }`}
                  >
                    {errors.email}
                  </p>
                </div>
              </div>
              <div className={"lower"}>
                <div className="input-long">
                  <p>Message</p>
                  <textarea
                    onChange={handleInputChange}
                    type="text"
                    name="message"
                    placeholder="A place for your message"
                    value={formData.message}
                  />
                  <p
                    className={`form-error${
                      wasSubmitClicked && errors.message ? " visible" : ""
                    }`}
                  >
                    {errors.message}
                  </p>
                </div>
              </div>
              <div className="data-processing">
                <label className="custom-checkbox">
                  <input
                    checked={isConsentChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    name="consent"
                  />
                  <span className="checkmark"></span>
                </label>
                <p
                  onClick={handlePersonalDataClick}
                  className={`personal-data ${
                    !isConsentChecked && wasSubmitClicked ? "red" : ""
                  }`}
                >
                  * I consent to the processing of personal data in order to
                  reply to the message.
                </p>
              </div>
              <div className="floating-form-btn">
                <a onClick={handleSubmit}>Send a message</a>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
