const data = [
  {
    id: 7,
    title: "Product passport",
    image: "img/oligamy/design/passport.png",
    link: "design/product-passport",
    details:
      "Optimization of data visualization. Product passport project for Billon company",
  },
  {
    id: 6,
    title: "Filip Puzyr website",
    image: "img/oligamy/design/filip-puzyr.png",
    link: "design/filip-puzyr",
    details:
      "Navigating the digital realm with seamless website responsiveness and YouTube integration",
  },
  {
    id: 5,
    title: "Document Reader",
    image: "img/oligamy/design/billon.png",
    link: "design/document-reader",
    details:
      "Transforming data management: Billon's Innovative blockchain solutions in finance",
  },
  {
    id: 1,
    title: "Oddsee",
    image: "img/oligamy/design/oddsee.png",
    link: "design/oddsee",
    details: "Oddsee is a new game formula called Betting Tournaments",
  },
  {
    id: 2,
    title: "Motherboard",
    image: "img/oligamy/design/mthrbrd.png",
    link: "design/mthrbrd",
    details:
      "All-in-one solution for managing DAO activities. Made with user in mind",
  },
  {
    id: 3,
    title: "We’re Gonna Make It – Fifa World Cup 2022",
    image: "img/oligamy/design/wgmi.png",
    link: "design/wgmi",
    details:
      "For football fans from the entire cryptosphere, we have something extraordinary!",
  },
  {
    id: 4,
    title: "Awesome landing page",
    image: "img/oligamy/design/landing.png",
    link: "design/metaverse",
    details: "Build your own special place in this unique metaverse",
  },
  // {
  //   id: 5,
  //   title: "Graphic",
  //   image: "img/project/project-5.jpg",
  //   category: "Graphic",
  //   details: "Art Deco Cocktails",
  // },
  // {
  //   id: 6,
  //   title: "3D Design",
  //   image: "img/project/project-6.jpg",
  //   category: "3D Design",
  //   details: "Low Poly Base Mesh",
  // },
  // {
  //     id:7,
  //     title:"3D Design",
  //     image:"img/project/project-5.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:8,
  //     title:"3D Design",
  //     image:"img/project/project-1.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:9,
  //     title:"UI Kit",
  //     image:"img/project/project-2.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:10,
  //     title:"UI Kit",
  //     image:"img/project/project-4.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:11,
  //     title:"App",
  //     image:"img/project/project-3.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
  // {
  //     id:12,
  //     title:"App",
  //     image:"img/project/project-1.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
];

export default data;
