import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ProjectWrapCaseStudies from "../project/ProjectWrapCaseStudies";
import AnimateLine from "../../common/AnimateLine";
import Preloader from "../../layout/Preloader";
import LatesPost from "../home/LatesPost";
import { Helmet } from "react-helmet";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Sticky,
  MoveIn,
  FadeOut,
} from "react-scroll-motion";

import AnimationImg1 from "../../../assets/img/oligamy/case-studies/case-studies/metaverse-mcbook.png";
import AnimationImg2 from "../../../assets/img/oligamy/case-studies/case-studies/oddsee-phones.png";
import AnimationImg3 from "../../../assets/img/oligamy/case-studies/case-studies/wgmi-mcbook.png";
import FloatingEstimation from "../../common/floating-estimation/FloatingEstimation";

function CaseStudies() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    document.body.classList.remove("no-scroll");
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  // Animation settings

  const [travelTo, setTravelTo] = useState(-600);
  const [travelFrom, setTravelFrom] = useState(400);

  useEffect(() => {
    if (windowSize <= 649) {
      setTravelFrom(580);
      setTravelTo(-600);
    }
  }, [windowSize]);

  const FadeUpAnimation = batch(
    Fade(),
    MoveIn(0, travelFrom, 0, travelTo),
    Sticky()
  );

  const HeaderFadeAnimation = batch(
    FadeIn(),
    MoveIn(0, travelFrom, 0, travelTo),
    Sticky()
  );

  const FadeDownAnimation = batch(Sticky(), Fade());

  const FadeInAnimation = batch(Fade(), Sticky());

  const LowerTextAnimation = batch(FadeOut(1, 0), Sticky());

  const QuotationFadeInAnimation = batch(FadeIn(), Sticky());

  const QuotationFadeOutAnimation = batch(FadeOut(1, 0), Sticky());

  const None = batch(Sticky());

  // Metadata

  const pageTitle = "UX/UI Mobile & Web Design | Oligamy Design";
  const pageDescription =
    "Expertise in intuitive UX/UI Design tailored for your business. Experienced in Mobile App and Web Design & Development.";

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <title>{pageTitle}</title>
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className="creasoft-wrap"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <AnimateLine />
            <FloatingEstimation />

            <section className="page-section-offer">
              <div className="animation-container">
                <ScrollContainer>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={None}>
                      <span>Oligamy Experience</span>
                    </Animator>
                    <Animator animation={None}>
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        73% of companies <span>leverage <br /> design to outperform rivals.</span>
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={FadeDownAnimation}>
                      <span>Oligamy Experience</span>
                    </Animator>
                    <Animator animation={FadeInAnimation}>
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        73% of companies <span>leverage <br /> design to outperform rivals.</span>
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage>
                    <div className="scroll-animation_1 image">
                      <Animator
                        className="image-wrap"
                        animation={FadeUpAnimation}
                      >
                        <img src={AnimationImg1} alt="" />
                      </Animator>
                    </div>
                  </ScrollPage>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={HeaderFadeAnimation}>
                      <span>Oligamy Sense</span>
                    </Animator>
                    <Animator
                      className="longer-text"
                      animation={FadeInAnimation}
                    >
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        40% of people{" "}
                        <span>
                          respond better to <br /> visual information than text-only.
                        </span>
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={FadeDownAnimation}>
                      <span>Oligamy Sense</span>
                    </Animator>
                    <Animator
                      className="longer-text"
                      animation={LowerTextAnimation}
                    >
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        40% of people{" "}
                        <span>
                          respond better to <br /> visual information than text-only.
                        </span>
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage>
                    <div className="scroll-animation_1 image">
                      <Animator
                        className="image-wrap oddsee"
                        animation={FadeUpAnimation}
                      >
                        <img src={AnimationImg2} alt="" />
                      </Animator>
                    </div>
                  </ScrollPage>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={HeaderFadeAnimation}>
                      <span>Oligamy Design</span>
                    </Animator>
                    <Animator animation={FadeInAnimation}>
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        <span>High-quality images drive</span> <br /> 67% purchase
                        decisions.
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage className="scroll-animation_1">
                    <Animator animation={FadeDownAnimation}>
                      <span>Oligamy Design</span>
                    </Animator>
                    <Animator animation={LowerTextAnimation}>
                      <section className="animation-lower">
                        {/* prettier-ignore */}
                        <p>
                        <span>High-quality images drive</span> <br /> 67% purchase
                        decisions.
                      </p>
                      </section>
                    </Animator>
                  </ScrollPage>
                  <ScrollPage>
                    <div className="scroll-animation_1 image">
                      <Animator
                        className="image-wrap"
                        animation={FadeUpAnimation}
                      >
                        <img src={AnimationImg3} alt="" />
                      </Animator>
                    </div>
                  </ScrollPage>

                  {/* <ScrollPage>
                    <Animator animation={QuotationFadeInAnimation}>
                      <div className="estimation-banner-wrapper bg-[#173d46] w-[100vw] h-[100vh] z-2 relative">
                        <div className="container wrapper max-w-[1320px] h-[100%]">
                          <div className="design-estimation-banner">
                            <h1>Shape your vision, design with us!</h1>
                            <a href="mailto:hello@oligamy.com">
                              <button>Request quotation</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Animator>
                  </ScrollPage> */}
                  {/* <ScrollPage>
                    <Animator animation={None}>
                      <div className="estimation-banner-wrapper bg-[#173d46] w-[100vw] h-[100vh] z-2 relative">
                        <div className="container wrapper max-w-[1320px] h-[100%]">
                          <div className="design-estimation-banner">
                            <h1>Shape your vision, design with us!</h1>
                            <a href="mailto:hello@oligamy.com">
                              <button>Request quotation</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Animator>
                  </ScrollPage> */}
                  {/* <ScrollPage>
                    <Animator animation={QuotationFadeOutAnimation}>
                      <div className="estimation-banner-wrapper bg-[#173d46] w-[100vw] h-[100vh] z-2 relative">
                        <div className="container wrapper max-w-[1320px] h-[100%]">
                          <div className="design-estimation-banner">
                            <h1>Shape your vision, design with us!</h1>
                            <a href="mailto:hello@oligamy.com">
                              <button>Request quotation</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Animator>
                  </ScrollPage> */}
                </ScrollContainer>
              </div>
              <div className="container wrapper max-w-[1320px] wow animated fadeInUp">
                <ProjectWrapCaseStudies windowSize={windowSize} />
              </div>
              {/*<TestimonialDesign />*/}
              <LatesPost windowSize={windowSize} />
              <div className="title-wrap design m-[auto] wow animate fadeInUp container">
                <div
                  className={`${"sec-title"} ${"sec-title_alt"} design-blog`}
                >
                  <h3 className="case-studies-header">CONTACT US</h3>
                  <h2>Receive a personalized assessment of your needs!</h2>
                  <p className="text-[#565656] contact-text">
                    Fill out the contact form or send us an email, and our
                    experts will get back to you as soon as possible. Rest
                    assured that your needs will be our priority, and our
                    knowledge and experience will help you achieve your intended
                    goals.
                  </p>
                  <div className="contact-btns design">
                    <Link
                      to={`${process.env.PUBLIC_URL}/design/expectations-brief`}
                      onClick={scrollTop}
                    >
                      <button>Estimate project</button>
                    </Link>
                    <a
                      className="write-to-us_btn"
                      href="mailto:hello@oligamy.com"
                    >
                      Write to us
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default CaseStudies;
