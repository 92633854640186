import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);
function Testimonial() {
  const testimonialSlider = {
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    duration: 5000,
    speed: 1000,
    autoplay: {
      delay: 7000,
      isableOnInteraction: true,
    },
    spaceBetween: 200,
    slideToClickedSlide: true,

    breakpoints: {
      250: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 200,
      },
      992: {
        slidesPerView: 1,
      },
      1400: {
        slidesPerView: 1,
      },
      1800: {
        slidesPerView: 1,
      },
    },
  };
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [buttonsToggle, setButtonsToggle] = useState(true);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize <= 500) {
      setButtonsToggle(false);
    }
  }, [windowSize]);

  return (
    <>
      <section className="testimonial-area" id="designs">
        <div className="container-fluid p-0">
          <Swiper
            pagination={{ clickable: true }}
            navigation={buttonsToggle}
            {...testimonialSlider}
            className="swiper testimonial-slider w-100"
          >
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial home">
                  <div className="title-wrap_swiper">
                    <div className="sec-title sec-title_swiper white">
                      <span>DESIGN</span>
                      <h2>Product passport</h2>
                      <p className="fix-p">
                        Transforming data management: Billon's Innovative
                        blockchain solutions in finance
                      </p>
                    </div>
                    <div className="see-now_btn">
                      <Link
                        to={`${process.env.PUBLIC_URL}/design`}
                        onClick={scrollTop}
                      >
                        SEE ALL CASE STUDIES
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/case-studies/arrow-right.svg"
                          }
                          alt="see now arrow"
                        />
                      </Link>
                    </div>
                  </div>
                  <Link
                    className="slider-image"
                    to={`${process.env.PUBLIC_URL}/design/product-passport`}
                    onClick={scrollTop}
                  >
                    <img
                      className="slider-image"
                      src={
                        process.env.PUBLIC_URL +
                        "/img/oligamy/case-studies/passport.png"
                      }
                      alt="case studies passport"
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial home">
                  <div className="title-wrap_swiper">
                    <div className="sec-title sec-title_swiper white">
                      <span>DESIGN</span>
                      <h2>Document Reader</h2>
                      <p className="fix-p">
                        Transforming data management: Billon's Innovative
                        blockchain solutions in finance
                      </p>
                    </div>
                    <div className="see-now_btn">
                      <Link
                        to={`${process.env.PUBLIC_URL}/design`}
                        onClick={scrollTop}
                      >
                        SEE ALL CASE STUDIES
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/case-studies/arrow-right.svg"
                          }
                          alt="see now arrow"
                        />
                      </Link>
                    </div>
                  </div>
                  <Link
                    className="slider-image"
                    to={`${process.env.PUBLIC_URL}/design/document-reader`}
                    onClick={scrollTop}
                  >
                    <img
                      className="slider-image"
                      src={
                        process.env.PUBLIC_URL +
                        "/img/oligamy/case-studies/billon.png"
                      }
                      alt="case studies billon"
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide w-100">
                <div className="single-testimonial home">
                  <div className="title-wrap_swiper oddsee-swiper-title">
                    <div className="sec-title sec-title_swiper white">
                      <span>DESIGN</span>
                      <h2>Oddsee is not just another betting app </h2>
                      <p className="fix-p">
                        Oddsee is an online platform with betting tournaments
                        where users compete in predicting the results of sports
                        events.
                      </p>
                    </div>
                    <div className="see-now_btn">
                      <Link
                        to={`${process.env.PUBLIC_URL}/design`}
                        onClick={scrollTop}
                      >
                        SEE ALL CASE STUDIES
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/oligamy/case-studies/arrow-right.svg"
                          }
                          alt="see now arrow"
                        />
                      </Link>
                    </div>
                  </div>
                  <Link
                    className="oddsee-slide"
                    to={`${process.env.PUBLIC_URL}/design/oddsee`}
                    onClick={scrollTop}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/oligamy/case-studies/oddsee.png"
                      }
                      alt="case studies oddsee"
                      className="oddsee-testimonial"
                    />
                  </Link>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
