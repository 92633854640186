import { Link } from "react-router-dom";
import logError from "../../../helpers/logError";
import getPosts from "../../../api/action.getPosts";
import sortBlogPosts from "../../../helpers/sortBlogPosts";
import { useEffect, useState } from "react";

function LatesPost({ windowSize }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [sliceNumber, setSliceNumber] = useState(3);

  useEffect(() => {
    if (windowSize <= 991 && windowSize >= 767) {
      setSliceNumber(4);
    } else {
      setSliceNumber(3);
    }
  }, [windowSize]);

  const [posts, setPosts] = useState([]);

  // Posts fetching

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw "No posts";
          }
          setPosts(sortBlogPosts(rawPosts));
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <section className="blog-area design">
        <div className="container wrapper max-w-[1320px]">
          <div
            className="title-wrap design-blog -6 wow animate fadeInUp flex justify-center"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title design-blog">
              <span>OLIGAMY DESIGN BLOG</span>
              <h2 className="latest-blog-header">Latest post</h2>
              <p className="latest-blog-text">
                This is the place where you will expand your knowledge.
              </p>
            </div>
          </div>
          <div
            className="row g-4 wow animate fadeInUp flex justify-between project-items"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            {posts.slice(0, sliceNumber).map((post, id) => {
              const img =
                post?.attributes?.thumbnail?.data?.attributes?.formats?.large
                  ?.url;
              return (
                <div
                  key={id}
                  className="single-blog latest-post col-md-6 col-lg-4"
                >
                  <div className="blog-thumb">
                    <Link
                      to={`${process.env.PUBLIC_URL}/design/blog-post/${post.id}`}
                      onClick={scrollTop}
                    >
                      <img
                        src={process.env.REACT_APP_STRAPI_URL + img}
                        alt="Thumbnail"
                      />
                    </Link>
                    {/*<div className="tag">*/}
                    {/*  <Link*/}
                    {/*    to={`${process.env.PUBLIC_URL}/project/${post.id}`}*/}
                    {/*    onClick={scrollTop}*/}
                    {/*  >*/}
                    {/*    UI/UX*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                  </div>
                  <div className="blog-inner latest">
                    <div className="author-date latest-post">
                      <p>By {post?.attributes?.author}</p>
                      <p>{post?.attributes?.date}</p>
                    </div>
                    <h4>
                      <Link
                        to={`${process.env.PUBLIC_URL}/design/blog-post/${post.id}`}
                        onClick={scrollTop}
                        className="latest-post"
                      >
                        {post?.attributes?.title}
                      </Link>
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="see-all-articles-blog-wrap">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/design/blog`}
            >
              See all articles
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default LatesPost;
