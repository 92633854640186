function AboutArea(props) {
  return (
    <>
      <section className="about-area sec-mar-bottom" id="about">
        <div className="container">
          <div className="row">
            <div className="about-section_upper flex justify-between items-center  mb-[150px] 991px:mb-[50px] 449px:mb-[10px]">
              <div
                className="col-lg-6 or-2 wow animate fadeIn w-[550px] 1399px:w-[400px] 1399px:mr-[50px] 991px:mr-0 991px:w-[100%] 767px:w-[100%]"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div
                  className={`${"sec-title layout2 sec-title_about"} ${
                    props.white
                  }`}
                >
                  <h3 className="technology-span">Discover Oligamy Software</h3>
                  <h2>Your Trusted Polish Software House</h2>
                </div>
                <div className={`${"about-left"} ${props.layoutClass}`}>
                  <p className="text-[#565656]">
                    Oligamy Software is a leading software development company
                    located in Gdańsk, Poland. Founded and operated by two
                    highly experienced software engineers with over a decade of
                    expertise each,our company has grown to employ a dedicated
                    team of over 25 professionals. We excel in all aspects of
                    software development, encompassing comprehensive services
                    from business analysis and UX/UI design to development and
                    ongoing maintenance.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 or-1 wow animate fadeIn"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="about-right">
                  <div className="banner-1 flex justify-end">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/oligamy/about/right.png"
                      }
                      alt="people in office"
                      className="991px:hidden"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/oligamy/about/right-tablet.png"
                      }
                      alt="people in office"
                      className="hidden 991px:block"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="about-section_lower flex justify-between items-center">
              <div
                className="col-lg-6 or-1 wow animate fadeIn"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="about-right 991px:mt-[72px]">
                  <div className="banner-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/oligamy/about/left.png"
                      }
                      alt="people in office"
                      className="991px:hidden"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/oligamy/about/left-tablet.png"
                      }
                      alt="people in office"
                      className="hidden 991px:block"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 or-2 wow animate fadeIn w-[550px] 1399px:w-[400px] 1399px:ml-[50px] 991px:ml-0 991px:w-[100%] 767px:w-[100%]"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div
                  className={`${"sec-title layout2 sec-title_about"} ${
                    props.white
                  }`}
                >
                  <h3 className="technology-span">
                    Efficient Agile Development
                  </h3>
                  <h2>Our Collaborative Workflow</h2>
                </div>
                <div className={`${"about-right"} ${props.layoutClass}`}>
                  <p className="text-[#565656]">
                    At Oligamy Software, we prioritize agile development
                    methodologies with a focus on short feedback cycles. Our
                    approach ensures continuous progress updates, with at least
                    one weekly presentation of the work completed. This allows
                    you to review and provide feedback promptly, ensuring that
                    your investment is maximized and your desired outcomes are
                    met. Our primary objective is to deliver exceptional value
                    for your budget, providing you with precisely what you need
                    and want.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutArea;
