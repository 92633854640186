const StagesOfDev = () => {
  return (
    <section className="stages py-[150px] 991px:py-[62px] 699px:py-[50px] 569px:pt-[16px] 569px:pb-[60px]">
      <div className="container">
        <div className="sec-title sec-title_alt white wow animate fadeInUp">
          <h3>STAGES OF SOFTWARE DEVELOPMENT</h3>
          <h2>We work in a swift agile methodology</h2>
        </div>

        <div className="points-wrapper">
          <div className="point wow animate fadeInUp">
            <h1>01</h1>
            <p>Individual approach</p>
          </div>
          <div className="point wow animate fadeInUp">
            <h1>02</h1>
            <p>Application development</p>
          </div>
          <div className="point wow animate fadeInUp">
            <h1>03</h1>
            <p>Client cooperation</p>
          </div>
          <div className="point wow animate fadeInUp">
            <h1>04</h1>
            <p>Immediate reaction to change</p>
          </div>
          <div className="point wow animate fadeInUp">
            <h1>05</h1>
            <p>A working system</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StagesOfDev;
