import { useEffect, useReducer, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import WOW from "wowjs";

import { ReactComponent as Logo } from "../../assets/img/oligamy/logo.svg";
function Header() {
  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  /*---------header top Sticky event----------*/
  const handleStickyHeader = () => {
    if (ref.current) {
      setSticky(window.pageYOffset > 0);
    }
  };

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleNavClick = () => {
    handleSidbarMenu();
    scrollTop();
  };

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "service":
        return { activeMenu: "service" };
      case "project":
        return { activeMenu: "project" };
      case "blog":
        return { activeMenu: "blog" };
      case "page":
        return { activeMenu: "page" };
      case "contact":
        return { activeMenu: "contact" };
      case "home":
        return { activeMenu: "home" };
      case "technologies":
        return { activeMenu: "technologies" };
      case "about":
        return { activeMenu: "about" };
      case "offer":
        return { activeMenu: "offer" };
      case "softwareDev":
        return { activeMenu: "softwareDev" };
      case "design":
        return { activeMenu: "design" };
      default:
        return { activeMenu: "" };
    }
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    const yOffsetAlt = -100;
    if (el.id === "about") {
      window.scrollTo({ top: yCoordinate + yOffsetAlt, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    dispatch({ type: "homeOne" });
    new WOW.WOW({
      live: false,
    }).init();
    window.addEventListener("scroll", handleStickyHeader);
    return () => {
      window.removeEventListener("scroll", () => handleStickyHeader);
    };
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [menuColor, setMenuColor] = useState("#fdfdfd");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const grey = "#565656";
    const blue = "#173d46";

    if (windowSize <= 1199) {
      setMenuColor(grey);
    } else {
      setMenuColor(blue);
    }
  }, [windowSize]);

  // Header highlights

  // Get all sections that have an ID defined
  const sections = document.querySelectorAll("section[id], footer[id]");

  // Add an event listener listening for scroll
  window.addEventListener("scroll", navHighlighter);
  function navHighlighter() {
    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 150;
      const sectionId = current.getAttribute("id");
      /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        const el = document?.querySelector(
          ".main-nav ul li a#link" + sectionId
        );
        el && el.classList.add("active");
      } else {
        const el = document?.querySelector(
          ".main-nav ul li a#link" + sectionId
        );
        el && el.classList.remove("active");
      }
    });
  }

  useEffect(() => {
    setTimeout(() => {
      navHighlighter();
      const linkContactElement = document.getElementById("linkcontact");
      if (linkContactElement) {
        linkContactElement.classList.remove("active");
      }
    }, 60);
  }, [location.pathname]);

  return (
    <>
      <header
        className={`${"header-area position_top alt"} ${
          isSticky ? "stick" : ""
        }`}
        ref={ref}
      >
        <div className="site-logo">
          <div className="logo">
            <Link to={"/"} onClick={scrollTop}>
              <Logo className="w-[130px]" />
            </Link>
          </div>
        </div>
        <div className="main-menu">
          <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav alt"}>
            <div className="mobile-menu-logo">
              <Link to={"/"} onClick={scrollTop}>
                <Logo className="w-[130px]" />
              </Link>
              <div className="remove" onClick={handleSidbarMenu}>
                <i className="bi bi-plus-lg" />
              </div>
            </div>
            <ul>
              {/* <li className="home-nav">
                <NavLink
                  style={{
                    color: state.activeMenu === "home" ? "#3fb7e2" : menuColor,
                  }}
                  onClick={() => {
                    handleNavClick();
                    dispatch({ type: "home" });
                  }}
                  to={`/`}
                >
                  Home
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  style={{
                    color:
                      state.activeMenu === "softwareDev"
                        ? "#3fb7e2"
                        : menuColor,
                  }}
                  onClick={() => {
                    handleNavClick();
                    dispatch({ type: "softwareDev" });
                  }}
                  to={`/`}
                >
                  Software development
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{
                    color:
                      state.activeMenu === "design" ? "#3fb7e2" : menuColor,
                  }}
                  onClick={() => {
                    handleNavClick();
                    dispatch({ type: "design" });
                  }}
                  to={`${process.env.PUBLIC_URL}/design`}
                >
                  Design
                </NavLink>
              </li>
              <li className="has-child active">
                <div className="nav-dropdown-item">
                  <HashLink
                    id="linkoffer"
                    onClick={() => {
                      handleNavClick();
                      dispatch({ type: "offer" });
                    }}
                    className={state.activeMenu === "offer" ? "active" : ""}
                    smooth
                    to="/#offer"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Our offer
                  </HashLink>
                  <i
                    id="chevron"
                    className="bi bi-chevron-down"
                    onClick={() => {
                      state.activeMenu !== "homeOne"
                        ? dispatch({ type: "homeOne" })
                        : dispatch({ type: null });
                    }}
                  />
                </div>
                <ul
                  className={
                    state.activeMenu === "homeOne"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <NavLink
                      onClick={handleNavClick}
                      to={`${process.env.PUBLIC_URL}/offer/web-and-mobile`}
                    >
                      Web & Mobile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={handleNavClick}
                      to={`${process.env.PUBLIC_URL}/offer/block-chain`}
                    >
                      Blockchain & Web3
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={handleNavClick}
                      to={`${process.env.PUBLIC_URL}/offer/machine-learning`}
                    >
                      AI & Machine learning
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={handleNavClick}
                      to={`${process.env.PUBLIC_URL}/offer/ui-ux`}
                    >
                      UI/UX
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  onClick={handleNavClick}
                  to={`${process.env.PUBLIC_URL}/design/blog`}
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <HashLink
                  id="linktechnologies"
                  onClick={() => {
                    handleSidbarMenu();
                    dispatch({ type: "technologies" });
                  }}
                  className={
                    state.activeMenu === "technologies" ? "active" : ""
                  }
                  smooth
                  to="/#technologies"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Technologies
                </HashLink>
              </li>
              <li>
                <HashLink
                  id="linkabout"
                  className={state.activeMenu === "about" ? "active" : ""}
                  onClick={() => {
                    handleSidbarMenu();
                    dispatch({ type: "about" });
                  }}
                  smooth
                  to="/#about"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  About us
                </HashLink>
              </li>
              <li className="has-child">
                <HashLink
                  id="linkcontact"
                  className={state.activeMenu === "contact" ? "active" : ""}
                  onClick={() => {
                    handleSidbarMenu();
                    dispatch({ type: "contact" });
                  }}
                  smooth
                  to="/#contact"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="nav-right">
          <div className="mobile-menu">
            <Link to="#" onClick={handleSidbarMenu} className={"cross-btn alt"}>
              <span className="cross-top" />
              <span className="cross-middle" />
              <span className="cross-bottom" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
