import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/web-and-mobile/web.png";
import pic1 from "../../../assets/img/oligamy/web-and-mobile/1.png";
import pic1Tablet from "../../../assets/img/oligamy/web-and-mobile/1.png";
import pic2 from "../../../assets/img/oligamy/web-and-mobile/2.png";
import pic3 from "../../../assets/img/oligamy/web-and-mobile/3.png";
import arrow from "../../../assets/img/oligamy/web-and-mobile/arrow.svg";
import useMediaQueries from "../../common/MediaQueries";
import OurServiceFragment from "../../common/OurServiceFragment";
import AnimateLine from "../../common/AnimateLine";
import Preloader from "../../layout/Preloader";
import { Helmet } from "react-helmet";
import FloatingForm from "../../common/floating-form/FloatingForm";

function WebAndMobile() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const isMobileMedia = useMediaQueries("(max-width: 599px)");
  const isTabletMedia = useMediaQueries(
    "(min-width: 600px) and (max-width: 1024px)"
  );
  const isLargeMedia = useMediaQueries("(min-width: 998px)");

  // Metadata

  const pageTitle = "Web & Mobile App Development | Oligamy Software & Design";
  const pageDescription =
    "Dive into diverse software technologies with our specialized engineers. Discover the breadth of development services Oligamy offers.";

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className="creasoft-wrap"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <AnimateLine />

            <section className="page-section-offer">
              <div className="position-relative">
                <div className="container wrapper max-w-[1320px]">
                  <h1 className="title position-absolute 699px:text-[39px]">
                    Web and mobile <br /> applications development <br />{" "}
                  </h1>
                </div>
                <img className="w-100 offer-header" srcSet={TopPic} />
              </div>
              <div className="container wrapper max-w-[1320px]  wow animated fadeInUp">
                <div className={`row ${isLargeMedia && "my-5 py-5"}`}>
                  <div
                    className={`${
                      !isLargeMedia ? "col-sm-12" : "col-sm-6"
                    } block-chain-offer`}
                  >
                    <h2
                      className="pt-5 h2-sub-page"
                      style={{ color: "#256C86" }}
                    >
                      We originate from web and mobile applications development
                    </h2>
                    <p className="py-4 text-[#565656]">
                      Our software engineers specialize in many different
                      technologies including:
                    </p>
                    <div>
                      <div className="list-group list-group-horizontal p-sub-page row justify-content-between">
                        <div className="col-5 col-md-5 col-sm-5">
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>Node.js</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>Nest.js</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>React.js</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>Angular</li>
                          </ul>
                        </div>
                        <div className="col-5 col-md-5 col-sm-5">
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>React Native</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>Flutter</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>Swift</li>
                          </ul>
                          <ul className="list-group list-group-horizontal">
                            <li>
                              <img className="arrow-img" srcSet={arrow} />
                            </li>
                            <li>.Net</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <p className={`p-sub-page ${isMobileMedia && "py-4"}`}>
                      The team is supported by experienced business analysts and
                      software architects.
                    </p>
                  </div>
                  {isLargeMedia && <OurServiceFragment />}
                </div>
                <div className={`row mb-[198px] 991px:mb-[72px]`}>
                  {isMobileMedia ? (
                    <>
                      <img
                        className="col-sm-12 web-img"
                        srcSet={pic1}
                        alt="sub-page-blockchain"
                      />
                    </>
                  ) : isTabletMedia ? (
                    <>
                      <img
                        className="col-sm-12 mt-5"
                        srcSet={pic1Tablet}
                        alt="sub-page-blockchain"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={`${pic1}`}
                        alt="sub-page-blockchain"
                      />
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={`${pic2}`}
                        alt="sub-page-blockchain"
                      />
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={`${pic3}`}
                        alt="sub-page-blockchain"
                      />
                    </>
                  )}
                </div>
              </div>
              {!isLargeMedia && <OurServiceFragment />}
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default WebAndMobile;
