import React, { useState, useEffect } from "react";

const questionMapping = {
  step1: "What is the goal of your project?",
  step2: [
    "Do you already have any guidelines regarding the appearance (e.g. logo, colors) of the project?",
    "Do you have specific inspirations or examples of websites/apps that you like?",
  ],
  step3:
    "Do you have any special features or interactions you expect from the design?",
  step4: [
    "Do you have content for your website/application, including texts, images, and multimedia, or do you need help with its creation (copywriting, graphic design, animations/multimedia) and promotion (SEO, Social Media)",
    "Do you need content promotion?",
  ],
  step5: "Describe your needs if you want",
};

export default function AnswerDatabase({
  userResponses,
  currentStep,
  isScrollActive,
}) {
  const [responsesArray, setResponsesArray] = useState([]);

  useEffect(() => {
    const newArray = Object.entries(userResponses).map(([step, values]) => ({
      step,
      ...values,
    }));
    setResponsesArray(newArray);
  }, [userResponses]);

  return (
    <div className="answers-container-wrapper">
      <div
        className={`answers-container-opacity ${
          isScrollActive ? "block" : "hidden"
        }`}
      ></div>
      <div
        className={`answers-container ${isScrollActive ? "pr-[10px]" : ""} ${
          isScrollActive ? "overflow-y-scroll" : ""
        }`}
      >
        {currentStep === 1 ? (
          <div className="empty-database flex flex-col gap-[16px] items-center">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
              >
                <path
                  d="M9 7H1M14 1H1M14 13H1M17 5L13 9M13 5L17 9"
                  stroke="#A9A9A9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="text-[14px] leading-[150%] text-[#878787] max-w-[200px] text-center">
              You don't have any selected answers yet
            </p>
          </div>
        ) : (
          <>
            {responsesArray
              .filter(
                (response) => parseInt(response.step.substring(4)) < currentStep
              )
              .map((response, index) => {
                if (index === 1 || index === 3) {
                  // Render two .answer divs for indices 1 and 3
                  return (
                    <React.Fragment key={index}>
                      <div className="answer" key={index}>
                        <div className="upper flex justify-between items-start gap-[8px]">
                          <h3 className="text-[18px] text-[#235f6e] text-left font-600 leading-[150%]">
                            {questionMapping[response.step][0]}
                          </h3>
                          {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          className="pen-icon"
                        >
                          <path
                            d="M5 19.5H6.4L15.025 10.875L13.625 9.475L5 18.1V19.5ZM19.3 9.425L15.05 5.225L16.45 3.825C16.8333 3.44167 17.3043 3.25 17.863 3.25C18.4217 3.25 18.8923 3.44167 19.275 3.825L20.675 5.225C21.0583 5.60833 21.2583 6.071 21.275 6.613
                          C21.2917 7.155 21.1083 7.61733 20.725 8L19.3 9.425ZM17.85 10.9L7.25 21.5H3V17.25L13.6 6.65L17.85 10.9ZM14.325 10.175L13.625 9.475L15.025 10.875L14.325 10.175Z"
                            fill="#256C86"
                          />
                        </svg> */}
                        </div>
                        {Array.isArray(response.section1Option) ? (
                          response.section1Option.map((link, index) => (
                            <div
                              className="lower mt-[8px] flex items-center gap-[8px]"
                              key={index}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                                  fill="#878787"
                                />
                              </svg>
                              <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                                {link}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="lower mt-[8px] flex items-center gap-[8px]">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                              {response.section1Option}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="answer" key={index + "_extra"}>
                        <div className="upper flex justify-between items-start gap-[8px]">
                          <h3 className="text-[18px] text-[#235f6e] text-left font-600 leading-[150%]">
                            {questionMapping[response.step][1]}
                          </h3>
                          {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          className="pen-icon"
                        >
                          <path
                            d="M5 19.5H6.4L15.025 10.875L13.625 9.475L5 18.1V19.5ZM19.3 9.425L15.05 5.225L16.45 3.825C16.8333 3.44167 17.3043 3.25 17.863 3.25C18.4217 3.25 18.8923 3.44167 19.275 3.825L20.675 5.225C21.0583 5.60833 21.2583 6.071 21.275 6.613
                          C21.2917 7.155 21.1083 7.61733 20.725 8L19.3 9.425ZM17.85 10.9L7.25 21.5H3V17.25L13.6 6.65L17.85 10.9ZM14.325 10.175L13.625 9.475L15.025 10.875L14.325 10.175Z"
                            fill="#256C86"
                          />
                        </svg> */}
                        </div>
                        {Array.isArray(response.section2Option) ? (
                          response.section2Option.map((link, index) => (
                            <div
                              className="lower mt-[8px] flex items-center gap-[8px]"
                              key={index}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                                  fill="#878787"
                                />
                              </svg>
                              <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                                {link}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="lower mt-[8px] flex items-center gap-[8px]">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                              {response.section2Option}
                            </p>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                } else {
                  // Render a single .answer div for other indices
                  return (
                    <div className="answer" key={index}>
                      <div className="upper flex justify-between items-start gap-[8px]">
                        <h3 className="text-[18px] text-[#235f6e] text-left font-600 leading-[150%]">
                          {questionMapping[response.step]}
                        </h3>
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        className="pen-icon"
                      >
                        <path
                          d="M5 19.5H6.4L15.025 10.875L13.625 9.475L5 18.1V19.5ZM19.3 9.425L15.05 5.225L16.45 3.825C16.8333 3.44167 17.3043 3.25 17.863 3.25C18.4217 3.25 18.8923 3.44167 19.275 3.825L20.675 5.225C21.0583 5.60833 21.2583 6.071 21.275 6.613
                          C21.2917 7.155 21.1083 7.61733 20.725 8L19.3 9.425ZM17.85 10.9L7.25 21.5H3V17.25L13.6 6.65L17.85 10.9ZM14.325 10.175L13.625 9.475L15.025 10.875L14.325 10.175Z"
                          fill="#256C86"
                        />
                      </svg> */}
                      </div>
                      {response.selectedOptions?.map((option, optionIndex) => (
                        <div
                          className="lower mt-[8px] flex items-center gap-[8px]"
                          key={optionIndex}
                        >
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                            {option}
                          </p>
                        </div>
                      ))}
                      {response.textareaValue && (
                        <div className="lower mt-[8px] flex items-center gap-[8px]">
                          {/* Render your textareaValue here */}
                          <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                            {response.textareaValue}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>
    </div>
  );
}
