import { Link } from "react-router-dom";

function BlogStandarLayoutTwo(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleOnClick = () => {
    scrollTop();
  };

  return (
    <>
      <div className="single-blog layout4">
        <div className="blog-thumb">
          <Link
            to={`${process.env.PUBLIC_URL}/design/blog-post/${props.postID}`}
            onClick={handleOnClick}
          >
            <img
              src={process.env.REACT_APP_STRAPI_URL + props.image}
              alt="Thumbnail"
            />
          </Link>
        </div>
        <div className="blog-inner">
          <div className="author-date">
            <p>By {props.author}</p>
            <p>{props.date}</p>
          </div>
          <h4>
            <Link
              to={`${process.env.PUBLIC_URL}/design/blog-post/${props.postID}`}
              onClick={scrollTop}
            >
              {props.title}
            </Link>
          </h4>
        </div>
      </div>
    </>
  );
}

export default BlogStandarLayoutTwo;
