import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/billon/top.png";
import AnimateLine from "../../common/AnimateLine";

import AboutImage from "../../../assets/img/oligamy/case-studies/passport/about.png";

import Preloader from "../../layout/Preloader";

import ReportsImg from "../../../assets/img/oligamy/case-studies/passport/reports.png";

import DesignSystem from "../../../assets/img/oligamy/case-studies/passport/design-system.png";
import DesignSystem2 from "../../../assets/img/oligamy/case-studies/passport/design-system2.png";

import Gallery1_1 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/1/1.png";
import Gallery1_2 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/1/2.png";
import Gallery1_3 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/1/3.png";

import Gallery2_1 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/2/1.png";
import Gallery2_2 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/2/2.png";
import Gallery2_3 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/2/3.png";
import Gallery2_4 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/2/4.png";

import Gallery3_1 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/3/1.png";
import Gallery3_2 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/3/2.png";
import Gallery3_3 from "../../../assets/img/oligamy/case-studies/passport/image-gallery/3/3.png";

import ReactPlayer from "react-player/lazy";

import Film1 from "../../../assets/img/oligamy/case-studies/passport/films/1.mp4";
import Film2 from "../../../assets/img/oligamy/case-studies/passport/films/2.mp4";
import Film3 from "../../../assets/img/oligamy/case-studies/passport/films/3.mp4";
import FloatingForm from "../../common/floating-form/FloatingForm";

function ProductPassport() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <AnimateLine />

          <section className="page-section  mb-[244px] 500px:mb-[196px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Product passport project for <br className="500px:hidden" />
                  Billon company <br />
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div className="app-data wow animated fadeIn">
                <h3>Client: Billon</h3>
                <h3>Year: 2023</h3>
              </div>
              <div className="about-app wgmi billon">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">
                    Optimization of data visualization. Product passport project
                    for Billon company.
                  </h1>
                  <p className="billon">
                    Our latest project by the Oligamy Software team for Billon
                    Company has focused on creating comprehensive data sets that
                    meet the unique needs of European Commission institutes. The
                    goal was to present complex data in a clear and engaging
                    manner. As part of this project, we developed a certificate
                    search engine and dedicated projects for various types of
                    certificates.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="billon preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="film mt-[144px] 500px:mt-[32px]">
                <ReactPlayer
                  url={Film1}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="passport-tiles mt-[144px] 500px:mt-[64px]">
                <div className="upper">
                  <div className="tile bg-[#e58300]">
                    <h2>About Product Passport</h2>
                    <p>
                      Battery Product Passport is Billon's groundbreaking
                      program, providing complete transparency in the product
                      value chain. Compliant with strict European Union
                      standards, this advanced system acts as a 'seal of
                      quality,' enabling consumers to track the sustainable
                      lifecycle of products (for example batteries). This
                      innovative approach transforms complex data into
                      understandable information, helping consumers make
                      informed purchasing decisions in line with the latest EU
                      standards.
                    </p>
                  </div>
                </div>
                <div className="lower">
                  <div className="tile bg-[#246bfd]">
                    <h2>Challenge</h2>
                    <p>
                      The main challenge was to create an intuitive platform
                      that allows for efficient searching and presentation of
                      diverse certificates while maintaining consistency and
                      aesthetics.
                    </p>
                  </div>
                  <div className="tile bg-[#00b769]">
                    <h2>Solution</h2>
                    <p>
                      We leveraged a previously developed Design System,
                      adapting it to the project's specifics. Advanced search
                      features were implemented to enable the quick retrieval of
                      specific certificates. Each type of certificate was
                      individually designed, highlighting key information and
                      facilitating understanding.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="film mt-[144px] 500px:mt-[32px]">
                <ReactPlayer
                  url={Film3}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1 className="text-center">
                  Implementation of the <br />
                  Design System
                </h1>
                <p className="text-[#565656] mb-[72px] max-w-[787px] text-center 911px:max-w-[100%]">
                  We utilized an existing Design System, allowing us to
                  consolidate the project with previous work for Billon. This
                  efficient use of resources enabled a quick and consistent
                  project implementation.
                </p>
                <img
                  src={DesignSystem}
                  alt="design system"
                  className="wow animate fadeInUp"
                />
                <img
                  src={DesignSystem2}
                  alt="ipad mini"
                  className="wow animate fadeInUp"
                />
              </div>
              <div className="film mt-[144px] 500px:mt-[32px]">
                <ReactPlayer
                  url={Film2}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1 className="text-center">The battery certifications</h1>
                <p className="text-[#565656] mb-[72px] max-w-[787px] text-center 911px:max-w-[100%] 500px:mb-[64px]">
                  The Battery Certification System includes certificates
                  confirming the chemical properties of the cell, quality
                  standards for cells, acceptances of compliance for battery
                  packs with standards, technical reviews, certificates
                  post-repair and repurposing, recycling, waste management, and
                  a general compliance certificate with standards for batteries.
                </p>
                <div className="passport-image-gallery">
                  <div className="left">
                    <img src={Gallery1_1} alt="" />
                    <img src={Gallery1_2} alt="" />
                    <img src={Gallery1_3} alt="" />
                  </div>
                  <div className="middle">
                    <img src={Gallery2_1} alt="" />
                    <img src={Gallery2_2} alt="" />
                    <img src={Gallery2_3} alt="" />
                    <img src={Gallery2_4} alt="" />
                  </div>
                  <div className="right">
                    <img src={Gallery3_1} alt="" />
                    <img src={Gallery3_2} alt="" />
                    <img src={Gallery3_3} alt="" />
                  </div>
                </div>
                <div className="passport-summary">
                  <p>
                    The Product Passport project for Billon is another example
                    of our ability to deliver comprehensive solutions tailored
                    to the unique needs of the client. Effectively leveraging
                    existing tools and expertise, we have crafted a solution
                    that seamlessly supports the operations of European
                    Commission institutes.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default ProductPassport;
