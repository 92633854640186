import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import data from "../../../data/dataCaseStudies";

function ProjectCardFilter({ isMore, windowSize }) {
  const [sliceNumber, setSliceNumber] = useState(3);

  useEffect(() => {
    if (windowSize <= 991 && windowSize >= 767) {
      setSliceNumber(4);
    } else {
      setSliceNumber(3);
    }
  }, [windowSize]);

  useEffect(() => {
    setItems(slicedData);
  }, [sliceNumber]);

  const slicedData = data.slice(0, sliceNumber);

  const [items, setItems] = useState(slicedData);

  useEffect(() => {
    if (isMore) {
      setItems(data);
    } else setItems(data.slice(0, 3));
  }, [isMore]);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <div className="row g-4 project-items d-flex">
        {items.map((element) => {
          const { image, id, details, link, title } = element;
          return (
            <div key={id} className="col-md-6 col-lg-4 single-item">
              <div className="case-study-design">
                <div className="overflow-hidden rounded-[16px] trigger">
                  <Link to={link} onClick={scrollTop}>
                    <img src={image} alt="" className="our-projects-img" />
                  </Link>
                </div>
                <h4 className="text-[25px] text-[#173d46] leading-[30px] mb-[12px] mt-[32px] study-design-title">
                  {title}
                </h4>
                <p className="text-[#565656] mb-[30px]">{details}</p>
                <div className="see-now_btn design text-[#256c86]">
                  <Link to={link} onClick={scrollTop}>
                    SEE DETAILS
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.56869 20.528L10.1406 22.0768L21.0315 11.346L10.1406 0.615234L8.56869 2.16401L17.0047 10.4759H0.260132V12.2161H17.0047L8.56869 20.528Z"
                      />
                    </svg>
                    {/*<img*/}
                    {/*  src={*/}
                    {/*    process.env.PUBLIC_URL +*/}
                    {/*    "/img/oligamy/design/arrow-right_design.svg"*/}
                    {/*  }*/}
                    {/*  alt=""*/}
                    {/*/>*/}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProjectCardFilter;
