import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap best-choice wow animate fadeInUp">
            <div className="sec-title sec-title_alt offer-title">
              <h3>CHOOSE THE BEST</h3>
              <h2>Why our software development services stand out</h2>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="Expertise at its best"
                details="Professional solutions tailored for you"
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="Always by your side"
                details="Reliable and responsive collaboration with clients"
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="Unleashing innovation"
                details="Harnessing new technologies for everyday success"
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="No more delays"
                details="Agile methodology for efficient and swift software development"
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="Unmatched precision"
                details="Meticulously executed projects for flawless results"
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp 767px:flex 767px:justify-center 449px:justify-start"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                title="A legacy of excellence"
                details="Years of experience crafting stellar business applications"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
