import { useEffect, useState } from "react";
import Footer from "./common/Footer";
import Header1 from "./common/Header";
import Header2 from "./common/Header2";
import Header3 from "./common/Header3";
import { useLocation } from "react-router-dom";
import FloatingForm from "./common/floating-form/FloatingForm";

const URLS = [
  "/design/wgmi",
  "/offer/ui-ux",
  "/offer/block-chain",
  "/offer/machine-learning",
  "/offer/web-and-mobile",
  "/design/oddsee",
  "/design/mthrbrd",
  "/design/metaverse",
  "/design/document-reader",
  "/design/filip-puzyr",
  "/design/product-passport",
  "/design/blog",
  "/design/blog-post",
  "/design/blog-post/",
  "",
];

const Layout = ({ children }) => {
  const location = useLocation();
  const [headerType, setheaderType] = useState(1);

  useEffect(() => {
    if (URLS.includes(location.pathname)) {
      setheaderType(1);
    } else if (location.pathname.startsWith("/design/blog-post/")) {
      setheaderType(1);
    } else if (
      location.pathname.endsWith("/design") ||
      location.pathname.endsWith("/privacy-policy") ||
      location.pathname.endsWith("/design/expectations-brief")
    ) {
      setheaderType(3);
    } else {
      setheaderType(2);
    }
  }, [location]);

  let headerComponent;
  if (headerType === 1) {
    headerComponent = <Header1 />;
  } else if (headerType === 2) {
    headerComponent = <Header2 />;
  } else if (headerType === 3) {
    headerComponent = <Header3 />;
  }

  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    // Scroll to the top after a timeout (e.g., 1090 milliseconds) only if not Chrome
    if (!isChrome) {
      const timeoutId = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1090); // Adjust the timeout duration as needed

      // Clear the timeout if the component unmounts or if the URL changes before the timeout
      return () => clearTimeout(timeoutId);
    }
  }, [location.pathname]); // Trigger the effect when the pathname changes

  return (
    <>
      {headerComponent}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
