import { Link } from "react-router-dom";
import useMediaQueries from "./MediaQueries";

export default function OurServiceFragment() {
  const isMobileMedia = useMediaQueries("(max-width: 576px)");
  const isTabletMedia = useMediaQueries(
    "(min-width: 576px) and (max-width: 997px)"
  );
  const isLargeMedia = useMediaQueries("(min-width: 998px)");

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div
      className={`${
        isLargeMedia ? "col-lg-4 offset-lg-2" : ""
      } services align-items-center d-flex py-[0px]`}
    >
      <div className="w-100 services-internal">
        <h3 style={{ marginBottom: 40, fontSize: 25 }}>Our Services</h3>
        <p>
          <Link to={`block-chain`} onClick={scrollTop}>
            Blockchain development
          </Link>
        </p>
        <div className="line"></div>
        <p>
          <Link to={`machine-learning`} onClick={scrollTop}>
            Machine learning and artificial intelligence
          </Link>
        </p>
        <div className="line"></div>
        <p>
          <Link to={`web-and-mobile`} onClick={scrollTop}>
            Web and mobile applications development
          </Link>
        </p>
        <div className="line"></div>
        <p>
          <Link to={`ui-ux`} onClick={scrollTop}>
            UI/UX, graphics design and animated content creation
          </Link>
        </p>
      </div>
    </div>
  );
}
