import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);
function PartnarArea() {
  const partnarSlider = {
    loop: true,
    slidesPerView: 4,
    spaceBetween: 100,
    speed: 1000,
    allowTouchMove: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 100,
      },
      1200: {
        slidesPerView: 3,
      },
      1399: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <>
      <section className="our-partner" id="technologies">
        <div className="container wow animate fadeInUp">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="sec-title white layout2 w-[320px] 991px:w-[100%] relative z-[999] bg-[#235f6e]">
                <h3 className="technology-span">TECHNOLOGIES</h3>
                <h2>Technologies we use</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <Swiper {...partnarSlider} className="swiper partner-slider">
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/azure.svg"
                        }
                        alt="azure-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/aws.svg"
                        }
                        alt="aws-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/react.svg"
                        }
                        alt="react-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/swift.svg"
                        }
                        alt="swift-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/css.svg"
                        }
                        alt="css-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/targit.svg"
                        }
                        alt="targit-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/html.svg"
                        }
                        alt="html-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/kubernetes.svg"
                        }
                        alt="kubernetes-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/vue.svg"
                        }
                        alt="vue-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/starcounter.svg"
                        }
                        alt="starcounter-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/python.svg"
                        }
                        alt="python-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/php.svg"
                        }
                        alt="php-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/angular.svg"
                        }
                        alt="angular-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/node.svg"
                        }
                        alt="node-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/rails.svg"
                        }
                        alt="rails-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/ethereum.svg"
                        }
                        alt="ethereum-img"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/oligamy/technologies/tensor.svg"
                        }
                        alt="tensor-img"
                      />
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PartnarArea;
