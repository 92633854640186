import { HashLink } from "react-router-hash-link";

function HeroArea() {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <>
      <section className="hero-area" id="hero">
        <div className="ken-burns-slideshow relative hero-area-img">
          <img
            src={process.env.PUBLIC_URL + "/img/oligamy/Subtract.svg"}
            alt="HeroBanner"
          />
        </div>
        <div className="hero-wrapper container">
          <div className="max-w-[100%]">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content homepage w-[792px] mt-[260px] 1399px:mt-[0px] relative z-[999]">
                  <h1 className="">Oligamy Software</h1>
                  <h2>
                    Driving Digital Transformation through Innovative Software
                    Development
                  </h2>
                  <div className="buttons mt-[64px] 769px:mt-[40px] 305px:mt-[20px]">
                    <div className="cmn-btn">
                      <HashLink
                        smooth
                        to="/#contact"
                        scroll={(el) => scrollWithOffset(el)}
                      >
                        Contact Us
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroArea;
