import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/filip-puzyr/top.png";
import AnimateLine from "../../common/AnimateLine";

import AboutImage from "../../../assets/img/oligamy/case-studies/filip-puzyr/about.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/filip-puzyr/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/filip-puzyr/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/filip-puzyr/about4.png";
import AboutImage5 from "../../../assets/img/oligamy/case-studies/filip-puzyr/about5.png";

import Preview from "../../../assets/img/oligamy/case-studies/filip-puzyr/preview.png";

import Preloader from "../../layout/Preloader";

import ReactPlayer from "react-player/lazy";

import Film1 from "../../../assets/img/oligamy/case-studies/filip-puzyr/film1.mp4";
import FloatingForm from "../../common/floating-form/FloatingForm";

function FilipPuzyr() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <AnimateLine />

          <section className="page-section mb-[244px] 500px:mb-[196px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute z-10 699px:text-[39px]">
                  Filip Puzyr - Website <br />
                  Responsiveness <br />
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%]" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div className="app-data wow animated fadeIn">
                <h3>Client: Filip Puzyr</h3>
                <h3>Year: 2023</h3>
              </div>
              <div className="about-app wgmi billon filip-puzyr">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">Key to the modern online world</h1>
                  <p className="billon">
                    In today's digital landscape, website responsiveness is a
                    crucial element of modern design. Whether you're using a
                    computer or smartphone, the mobile view with YouTube
                    integration ensures easy navigation, aesthetic appeal, and
                    access to the latest performances. This confirms that the
                    website adapts seamlessly to various devices, enhancing the
                    comfort of viewing and interacting with content.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Filip Puzyr preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="about-app wgmi billon filip-puzyr alt">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft 500px:mt-[96px]">
                    The role of responsiveness: Why it matters?
                  </h1>
                  <p className="billon">
                    esponsiveness goes beyond mere content adaptation; it
                    embodies flexibility and adaptability—key components for
                    success in today's multi-device landscape. Filip Puzyr's
                    website exemplifies this concept through its mobile menu,
                    where seamless navigation and intuitiveness hold the same
                    importance as they do on larger screens. This underscores
                    the website's ability to flawlessly adjust to various
                    devices, delivering a distinctive and user-friendly
                    experience for mobile users.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Filip Puzyr preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
            <div className="bg-[#262626]">
              <div className="container max-w-[1320px]">
                <div className="guide-user wgmi text wow animate fadeInUp">
                  <img src={Preview} alt="" className="wow animate fadeInUp" />
                </div>
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="about-app wgmi billon filip-puzyr mt-[120px] 500px:mt-[80px]">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">Tools supporting responsiveness</h1>
                  <p className="billon">
                    Responsive design is not just about creativity but also
                    about tools and technologies. Adaptive frameworks like
                    Bootstrap or Foundation provide ready-made solutions,
                    speeding up the design process. A/B tests adjust the
                    strategy to user preferences. Tools such as Google PageSpeed
                    Insights help optimize responsiveness. This is particularly
                    evident in the integration with the 'Buy a Ticket' portal on
                    Filip Puzyr's website, where responsiveness facilitates
                    convenient and quick ticket purchases for his performances
                    via phone.
                  </p>
                </div>
                <img
                  src={AboutImage3}
                  alt="Filip Puzyr preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
              <div className="passport-tiles mt-[144px] 500px:mt-[64px]">
                <div className="lower">
                  <div className="tile bg-[#16404F]">
                    <h2>Pros and cons of approaches</h2>
                    <p>
                      Introducing responsiveness comes with certain challenges,
                      such as initial financial and time investments. However,
                      the long-term value lies in increased reach, improved user
                      experience, and more effective marketing campaigns.
                    </p>
                  </div>
                  <div className="tile bg-[#2C7789]">
                    <h2>Financial benefits</h2>
                    <p>
                      Responsive websites may require a higher initial
                      investment but provide long-term savings. They eliminate
                      the need to maintain multiple separate versions of the
                      site, resulting in lower maintenance costs.
                    </p>
                  </div>
                </div>
                <div className="upper">
                  <div className="tile bg-[#3FB7E2]">
                    <h2>Practices for accessibility and responsive design</h2>
                    <p>
                      Responsible responsive design also considers accessibility
                      for individuals with disabilities. Implementing semantic
                      HTML tags, accessible labels for screen readers, and using
                      large, readable fonts are practices that enhance content
                      accessibility.
                    </p>
                    <h3 className="text-[25px] leading-[120%] text-white text-left mt-[24px] mb-[8px] 799px:text-[22px]">
                      Responsive design for Web vs. Mobile
                    </h3>
                    <p>
                      While there are similarities, such as brand consistency,
                      each screen format seems to emphasize different elements.
                      Mobile sites focus on optimized navigation tailored for
                      small screens. Tablets offer a balance between intuitive
                      navigation and content presentation, while desktops
                      prioritize comprehensive content display and
                      multi-dimensional navigation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="film filip-puzyr">
                <ReactPlayer
                  url={Film1}
                  width="100%"
                  height="auto"
                  loop={true}
                  playing={true}
                />
              </div>
              <div className="about-app wgmi billon filip-puzyr mt-[96px]">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">
                    Where to begin: Mobile-first approach
                  </h1>
                  <p className="billon">
                    Starting design from mobile devices and then expanding
                    versions for larger screens is a strategy that ensures the
                    most intuitive and efficient user experience.
                  </p>
                  <h3 className="text-[25px] text-left text-[#256C86] leading-[120%] mt-[24px] mb-[16px] 500px:text-[22px]">
                    Techniques supporting responsiveness
                  </h3>
                  <p className="billon">
                    Utilizing techniques like flexbox and grid layout, lazy
                    loading, and fluid typography are key elements of responsive
                    design.
                  </p>
                </div>
                <img
                  src={AboutImage4}
                  alt="Filip Puzyr preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="about-app wgmi billon filip-puzyr alt">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">Summary</h1>
                  <p className="billon">
                    Responsiveness is not just a trendy addition to a project;
                    it's a fundamental element that adapts our websites to the
                    ever-changing reality of the online world. It's an
                    investment in the future, where user expectations will grow,
                    and the diversity of devices will only increase.
                    Implementing responsiveness is not just a technical step;
                    it's a strategy that builds a bridge between the brand and
                    the user, providing unforgettable online experiences.
                  </p>
                </div>
                <img
                  src={AboutImage5}
                  alt="Filip Puzyr preview"
                  className="wow animated fadeInUp mthrbrd-about1"
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default FilipPuzyr;
