import AnimateLine from "../../common/AnimateLine";
import Service from "../service/Service";
import AboutArea from "./AboutArea";
import FeaturesArea from "./FeaturesArea";
import HeroArea from "./HeroArea";
import PartnarArea from "./PartnarArea";
import StagesOfDev from "./StagesOfDev";
import Testimonial from "./Testimonial";

function HomePageOne() {
  return (
    <>
      <div
        className="creasoft-wrap"
        // onClick={() => {
        //   document.querySelector(".main-nav").className.includes("slidenav") &&
        //     document.querySelector(".remove").click();
        // }}
      >
        <AnimateLine />
        <HeroArea />
        <FeaturesArea />
        <Service />
        <PartnarArea />
        <AboutArea />
        <StagesOfDev />
        <Testimonial />
      </div>
    </>
  );
}

export default HomePageOne;
