import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/machine-learning/ai.png";
import mobile from "../../../assets/img/oligamy/machine-learning/ai-tablet1.png";
import pc1 from "../../../assets/img/oligamy/machine-learning/ai1.png";
import useMediaQueries from "../../common/MediaQueries";
import OurServiceFragment from "../../common/OurServiceFragment";
import AnimateLine from "../../common/AnimateLine";
import Preloader from "../../layout/Preloader";
import { Helmet } from "react-helmet";
import FloatingForm from "../../common/floating-form/FloatingForm";

function MachineLearning() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const isMobileMedia = useMediaQueries("(max-width: 990px)");
  // const isTabletMedia = useMediaQueries("(min-width: 825px) and (max-width: 1023px)");
  const isLargeMedia = useMediaQueries("(min-width: 991px)");

  //Metadata

  const pageTitle =
    "AI & Machine Learning Solutions | Oligamy Software & Design";
  const pageDescription =
    "Specializing in natural language, sound processing, data analysis, computer vision, and robotic automation.";

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className="creasoft-wrap"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <AnimateLine />

            <section className="page-section-offer">
              <div className="position-relative">
                <div className="container wrapper max-w-[1320px]">
                  <h1 className="title position-absolute 699px:text-[39px]">
                    Machine learning <br /> and artificial intelligence{" "}
                  </h1>
                </div>
                <img className="w-100 offer-header" srcSet={TopPic} />
              </div>
              <div className="container wrapper max-w-[1320px] wow animated fadeInUp">
                <div className={`row ${!isMobileMedia && "my-5 py-5"}`}>
                  <div
                    className={`${
                      isMobileMedia ? "col-sm-12" : "col-sm-6"
                    } block-chain-offer`}
                  >
                    <h2
                      className="pt-5 h2-sub-page"
                      style={{ color: "#256C86" }}
                    >
                      Machine learning and AI
                    </h2>
                    <p className="py-4 p-sub-page">
                      Comprising mathematicians and algorithmic programmers with
                      robust academic credentials and extensive commercial
                      expertise. Our specializations encompass natural language
                      and sound processing, financial and biological data
                      analysis, computer vision, and robotic process automation.
                      trust us to deliver cutting-edge solutions tailored to
                      your needs!
                    </p>
                    {isLargeMedia && (
                      <img
                        className="w-100"
                        srcSet={pc1}
                        alt="sub-page-blockchain"
                      />
                    )}
                  </div>

                  {!isMobileMedia && <OurServiceFragment />}
                </div>

                <div className={`row ${!isMobileMedia && "pb-5 mb-5 mt-5"}`}>
                  {isMobileMedia && (
                    <>
                      <img
                        className="col-sm-12 machine-learning-img 500px:hidden"
                        srcSet={mobile}
                        alt="sub-page-blockchain"
                      />
                      <img
                        className="col-sm-12 machine-learning-img hidden 500px:block"
                        srcSet={pc1}
                        alt="sub-page-blockchain"
                      />
                    </>
                  )}
                </div>
              </div>
              {isMobileMedia && <OurServiceFragment />}
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default MachineLearning;
