import React from "react";
import { Link } from "react-router-dom";

const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

export default function FloatingEstimation() {
  return (
    <div className="floating-estimation">
      <h3 className="big">Shape your vision, design with us!</h3>
      <h3 className="small">Shape your vision!</h3>
      <Link
        to={`${process.env.PUBLIC_URL}/design/expectations-brief`}
        onClick={scrollTop}
      >
        <button>Estimate project</button>
      </Link>
    </div>
  );
}
