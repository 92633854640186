import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/block-chain/blockchain-pic.png";

import pc1 from "../../../assets/img/oligamy/block-chain/Frame121.png";
import pc2 from "../../../assets/img/oligamy/block-chain/Frame123.png";
import pc3 from "../../../assets/img/oligamy/block-chain/Frame124.png";
import useMediaQueries from "../../common/MediaQueries";
import OurServiceFragment from "../../common/OurServiceFragment";
import AnimateLine from "../../common/AnimateLine";
import Preloader from "../../layout/Preloader";

import { Helmet } from "react-helmet";
import FloatingForm from "../../common/floating-form/FloatingForm";

function BlockChain() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const isMobileMedia = useMediaQueries("(max-width: 825px)");
  const isTabletMedia = useMediaQueries(
    "(min-width: 825px) and (max-width: 992px)"
  );
  const isLargeMedia = useMediaQueries("(min-width: 992px)");

  //Metadata

  const pageTitle = "Custom Blockchain Technology | Oligamy Software & Design";
  const pageDescription =
    "Your partner in DeFi, DAO, and NFT development. Crafting cutting-edge EVM Smart Contracts in Solidity.";

  return (
    <>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className="creasoft-wrap"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <AnimateLine />

            <section className="page-section-offer">
              <div className="position-relative mb-[96px] 991px:mb-[0]">
                <div className="container wrapper max-w-[1320px]">
                  <h1 className="title position-absolute 699px:text-[39px]">
                    Blockchain <br />
                    development{" "}
                  </h1>
                </div>
                <img className="w-100 offer-header" srcSet={TopPic} />
              </div>
              <div className="container wrapper max-w-[1320px] wow animated fadeInUp">
                <div className="block-chain row mb-[144px] 991px:mb-[50px]">
                  <div
                    className={`${
                      isMobileMedia ? "col-sm-12" : "col-lg-6"
                    } block-chain-offer`}
                  >
                    <h2
                      className="pt-[48px] h2-sub-page"
                      style={{ color: "#256C86" }}
                    >
                      Blockchain
                    </h2>
                    <p className="pt-4 pb-5 p-sub-page">
                      Our dedicated blockchain branch is your go-to partner for
                      all things DeFi, DAO, and NFT development. Specializing in
                      crafting cutting-edge EVM based Smart Contracts in
                      Solidity, we cater to platforms like Ethereum and Polygon,
                      ensuring seamless functionality and security. Moreover, we
                      extend our expertise to the Cardano blockchain, deploying
                      NFTs with precision and finesse. But that's not all! Our
                      proficiency in Web3 integration empowers us to seamlessly
                      connect websites, both front-end and back-end, with
                      blockchain, opening up endless possibilities for your
                      digital ventures.
                    </p>
                    <h2
                      className="pt-5 h2-sub-page"
                      style={{ color: "#256C86" }}
                    >
                      Blockchain Offer
                    </h2>
                    <p className="pt-4 p-sub-page">
                      Swift feedback cycles and weekly progress updates
                      guarantee efficient resource allocation, ensuring you get
                      exactly what you desire. Our mission: delivering
                      unparalleled value for your investment.
                    </p>
                  </div>

                  {isLargeMedia && <OurServiceFragment />}
                </div>
                <div
                  className={`row ${
                    !isMobileMedia && "pb-5 mb-[150px] 991px:mb-[50px]"
                  }`}
                >
                  {isMobileMedia ? (
                    <>
                      <div className="alt_img">
                        <img
                          className="col-sm-12"
                          srcSet={pc1}
                          alt="sub-page-blockchain"
                        />
                      </div>
                    </>
                  ) : isTabletMedia ? (
                    <>
                      <div className="alt_img">
                        <img
                          className="col-sm-12"
                          srcSet={pc1}
                          alt="sub-page-blockchain"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={pc1}
                        alt="sub-page-blockchain"
                      />
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={pc2}
                        alt="sub-page-blockchain"
                      />
                      <img
                        className={`${isLargeMedia ? "col-sm-4" : "mb-4"}`}
                        srcSet={pc3}
                        alt="sub-page-blockchain"
                      />
                    </>
                  )}
                </div>
              </div>
              {!isLargeMedia && <OurServiceFragment />}
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default BlockChain;
