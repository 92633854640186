import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getPost from "../../../api/action.getPost";
import logError from "../../../helpers/logError";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Helmet } from "react-helmet";

function BlogDetailsContent() {
  const { id } = useParams();
  const [post, setPost] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchPostData = async () => {
      if (id) {
        try {
          const { data } = await getPost(id);
          if (isMounted) {
            setPost(data);
          }
        } catch (err) {
          logError(err);
        }
      }
    };

    fetchPostData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  // Define a custom image renderer for react-markdown
  const renderers = {
    // Customize how images are rendered
    image: ({ src, alt }) => <img src={src} alt={alt} />,
  };

  //Metadata

  const pageTitle = post?.attributes?.title;
  const pageDescription = post?.attributes?.content.split("\n\n")[0];

  return (
    <>
      <Helmet>
        <meta name="description" content={pageDescription} />
        {/* Open Graph Protocol (OGP) tags for Facebook and others */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />{" "}
        {/* Use the current page URL */}
        <meta property="og:type" content="website" />
        {/* Twitter Card tags for Twitter */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div className="blog-details-content">
        <div className="author-date layout2">
          <p>By {post?.attributes?.author}</p>
          <p>{post?.attributes?.date}</p>
        </div>
        <div className="post-title">
          <h1 className="first-header">{post?.attributes?.title}</h1>
        </div>
        {/*<div className="details-thumb post">*/}
        {/*  <img*/}
        {/*    src={require("../../../assets/img/oligamy/blog/" +*/}
        {/*      data.thumbnail +*/}
        {/*      ".jpg")}*/}
        {/*    alt="thumbnail"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="post-content">
          <ReactMarkdown
            linkTarget="_blank"
            transformImageUri={(uri) =>
              `${process.env.REACT_APP_STRAPI_URL}${uri}`
            }
          >
            {post?.attributes?.content}
          </ReactMarkdown>
          {/* {data.sections.map((section, id) => {
            if (section.type === "text") {
              return <p key={id}>{section.content}</p>;
            }
            if (section.type === "header") {
              return <h2 key={id}>{section.content}</h2>;
            }
            if (section.type === "subheader") {
              return <h3 key={id}>{section.content}</h3>;
            }
            if (section.type === "list") {
              return (
                <ul key={id}>
                  {section.positions.map((position, id) => {
                    return <li key={id}>{position.content}</li>;
                  })}
                </ul>
              );
            }
            if (section.type === "image") {
              return (
                <img
                  key={id}
                  src={require("../../../assets/img/oligamy/blog/" +
                    section.path +
                    ".jpg")}
                  alt="Blog"
                />
              );
            } else return <br />;
          })} */}
        </div>
        <div className="tag-share">
          <div className="share-blog">
            <span>Share on</span>
            <ul className="social-share-blog">
              <li>
                <FacebookShareButton url={window.location.href}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M27.3327 14.0001C27.3327 6.64008 21.3594 0.666748 13.9993 0.666748C6.63935 0.666748 0.666016 6.64008 0.666016 14.0001C0.666016 20.4534 5.25268 25.8267 11.3327 27.0667V18.0001H8.66602V14.0001H11.3327V10.6667C11.3327 8.09341 13.426 6.00008 15.9993 6.00008H19.3327V10.0001H16.666C15.9327 10.0001 15.3327 10.6001 15.3327 11.3334V14.0001H19.3327V18.0001H15.3327V27.2667C22.066 26.6001 27.3327 20.9201 27.3327 14.0001Z" />
                  </svg>
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton url={window.location.href}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 30 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M29.278 18.2921V28.1308H23.574V18.9508C23.574 16.6441 22.7487 15.0708 20.6847 15.0708C19.1087 15.0708 18.17 16.1321 17.758 17.1574C17.6073 17.5241 17.5687 18.0347 17.5687 18.5481V28.1308H11.862C11.862 28.1308 11.9393 12.5841 11.862 10.9721H17.5687V13.4041L17.5313 13.4601H17.5687V13.4041C18.326 12.2374 19.6793 10.5694 22.71 10.5694C26.4634 10.5694 29.278 13.0227 29.278 18.2921ZM5.89402 2.70142C3.94335 2.70142 2.66602 3.98142 2.66602 5.66542C2.66602 7.31208 3.90602 8.63075 5.81935 8.63075H5.85668C7.84735 8.63075 9.08335 7.31208 9.08335 5.66542C9.04868 3.98142 7.84735 2.70142 5.89402 2.70142ZM3.00468 28.1308H8.70868V10.9721H3.00468V28.1308Z" />
                  </svg>
                </LinkedinShareButton>
              </li>
              <li>
                <TwitterShareButton url={window.location.href}>
                  <svg
                    width="28"
                    height="23"
                    viewBox="0 0 28 23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M27.9461 2.99992C26.9194 3.46659 25.8127 3.77325 24.6661 3.91992C25.8394 3.21325 26.7461 2.09325 27.1727 0.746585C26.0661 1.41325 24.8394 1.87992 23.5461 2.14659C22.4927 0.999919 21.0127 0.333252 19.3327 0.333252C16.1994 0.333252 13.6394 2.89325 13.6394 6.05325C13.6394 6.50659 13.6927 6.94659 13.7861 7.35992C9.0394 7.11992 4.81273 4.83992 1.9994 1.38659C1.50607 2.22659 1.22607 3.21325 1.22607 4.25325C1.22607 6.23992 2.22607 7.99992 3.77273 8.99992C2.82607 8.99992 1.94607 8.73325 1.17273 8.33325V8.37325C1.17273 11.1466 3.14607 13.4666 5.7594 13.9866C4.92037 14.2162 4.03953 14.2481 3.18607 14.0799C3.54821 15.2166 4.25745 16.2111 5.21409 16.9238C6.17073 17.6365 7.32666 18.0315 8.5194 18.0533C6.49757 19.6538 3.9914 20.519 1.41273 20.5066C0.959401 20.5066 0.506068 20.4799 0.0527344 20.4266C2.58607 22.0533 5.5994 22.9999 8.82607 22.9999C19.3327 22.9999 25.1061 14.2799 25.1061 6.71992C25.1061 6.46659 25.1061 6.22658 25.0927 5.97325C26.2127 5.17325 27.1727 4.15992 27.9461 2.99992Z" />
                  </svg>
                </TwitterShareButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetailsContent;
