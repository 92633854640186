function ServiceCard(props) {
  return (
    <>
      <div className={`${"single-service"} ${props.layoutClass}`}>
        <span>{props.serviceNumber}</span>
        <h4>{props.title}</h4>
        <p>{props.details}</p>
      </div>
    </>
  );
}

export default ServiceCard;
